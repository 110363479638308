import {
    Address,
    CHAIN_SETTING,
    Font,
    Image,
    MessageColor,
    PostcardSettings,
    REPLY_BACK_SETTING
} from "../../../utils/postcard";
import {LAYOUT} from "../../../utils/layout";
import {
    Box,
    Button,
    Card,
    CardContent,
    Dialog,
    DialogActions,
    DialogContent,
    IconButton,
    Typography, useMediaQuery, useTheme
} from "@mui/material";
import StaticPostcardBack from "../postcard/templates/StaticPostcardBack";
import {useState} from "react";
import getSmallImageComponent from "../SmallImageComponent";
import PriceBox from "../../PriceBox";



type CartPostcardRecapTemplateProps = {
    id: string
    message: string
    font?: Font
    color?: MessageColor
    location: string
    latitude: number
    longitude: number
    layout: LAYOUT
    images: Image[]
    settings?: PostcardSettings
    address: Address

    isDelete: boolean
    onRemovePostcard?: (id: string) => void

    price: number

    isReplyBack?: boolean
    isChain?: boolean
}

const CartPostcardRecapTemplate = (props: CartPostcardRecapTemplateProps) => {
    const { id, message, font, color,
        location, latitude, longitude,
        layout, images, settings, address,
        price, isDelete, onRemovePostcard, isReplyBack, isChain
    } = props

    const src = 'icons/app/cartstep/' + (isReplyBack ? 'reply.svg' : (isChain ? 'chain.svg' : 'single.svg'))
    const alt = isReplyBack ? 'Reply back' : (isChain ? 'Chain' : 'Single')

    const [showBack, setShowBack] = useState<boolean>(false)
    const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false)

    const theme = useTheme()
    const belowMedium = useMediaQuery(theme.breakpoints.down('md'))

    return <><Card sx={{ width: belowMedium ? '100%' : 800, mb: 2 }} className={'cart-postcard-recap'}>
        <CardContent
            sx={{display: 'flex', flexDirection: 'column', p: 3, pb: '16px !important'}}>
            <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', mb: 2 }}>
                <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                    <Box sx={{ height: '50px', width: '56px', pr: 2, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <img alt={alt} src={src} style={{ height: '80%', aspectRatio: 1 }} />
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'column', flexShrink: 0, width: 'calc(100% - 56px)', pr: 2 }}>
                        {/* TODO check for long strings and trim */}
                        <Typography variant={'h6'}
                                    sx={{textTransform: 'capitalize' }}>{address.fullName}</Typography>
                        <Typography variant={'body1'}
                                    sx={{textTransform: 'capitalize' }}>{address.city}</Typography>
                    </Box>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', width: '68px', flexShrink: 0 }}>
                    <PriceBox price={price} />
                </Box>
            </Box>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 1,
                alignItems: 'center',
                mr: 0,
                flexShrink: 0,
                width: '100%'
            }}>
                {showBack ? <Box sx={{width: '100%', height: 'auto'}}>
                    <StaticPostcardBack
                        message={message}
                        font={font?.name}
                        color={color?.hex}
                        location={location}
                        address={address}
                        latitude={latitude}
                        longitude={longitude}
                        isGiftQRCode={
                            !!settings && (settings.replyBackSetting === REPLY_BACK_SETTING.REPLY_PAID
                            || settings.chainSetting !== CHAIN_SETTING.NONE)
                        }
                    />
                </Box> : getSmallImageComponent(layout, images)}
                <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', mt: 1, justifyContent: 'space-between' }}>
                    <Box sx={{ width: 'fill-available' }}/>
                    <Box sx={{ flexShrink: 0 }}>
                        <Button onClick={() => setShowBack(!showBack)} variant={'contained'} color={'secondary'}>
                            <span>
                                <img width={28} height={28} alt={'Rotate'} src={'icons/app/cartstep/rotate.svg'} draggable={false} style={{ marginRight: 8 }}/>
                                    Turn
                            </span>
                        </Button>
                    </Box>
                    <Box
                        sx={{display: 'flex', flexDirection: 'row', justifyContent: 'end', width: '100%', mb: 3}}>
                        {isDelete && <Box>
                            <IconButton onClick={() => setDeleteDialogOpen(true)} className={'no-bg-on-disabled'}>
                                <img width={28} height={28} alt={'Delete'} src={'icons/app/cartstep/delete.svg'}
                                     draggable={false}/>
                            </IconButton>
                        </Box>}
                    </Box>
                </Box>
            </Box>
        </CardContent>
    </Card>
        {isDelete && <Dialog open={deleteDialogOpen}>
            <DialogContent>
                <Typography variant={'body1'}>
                    Are you sure you want to remove this postcard from the cart?
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button variant={'contained'} color={'primary'} onClick={() => {
                    onRemovePostcard && onRemovePostcard(id)
                    setDeleteDialogOpen(false)
                }}>Delete</Button>
                <Button variant={'contained'} color={'secondary'} onClick={() => {
                    setDeleteDialogOpen(false)
                }}><span>Do not delete</span></Button>
            </DialogActions>
        </Dialog>}
    </>
}

export default CartPostcardRecapTemplate