import React, {useContext, useEffect, useState} from "react";
import IsGreetingsContext from "./imagesselection/isGreetingsContext";
import GreetingsPostcardContext from "../greetings-postcard-app/utils/greetingsPostcardContext";
import CurrentPostcardContext from "../postcard-app/utils/currentPostcardContext";
import {Box, Typography, useMediaQuery, useTheme} from "@mui/material";
import {Font, MessageColor} from "../../../utils/postcard";
import usePersistState from "../../../utils/usePersistState";
import {getFonts, getMessageColors} from "../../../api/api";
import {DEFAULT_COLOR, DEFAULT_FONT, sortFonts} from "./postcardAppUtils";
import MessageSettings from "../postcard-app/messagesettings/MessageSettings";
import {CommonContextType} from "./utils/commonContextType";
import MessageAndLocationPostcardBack from "../../postcardrecap/postcard/templates/MessageAndLocationPostcardBack";

const MessageAndLocationStep = () => {
    const { isGreetings } = useContext(IsGreetingsContext)

    const context = (isGreetings ? GreetingsPostcardContext : CurrentPostcardContext) as unknown as React.Context<CommonContextType>

    const {
        message, setMessage, location, setLocation,  latitude, setLatitude, longitude, setLongitude,
        font, setFont, messageColor, setMessageColor
    } = useContext<CommonContextType>(context)

    const theme = useTheme()
    const belowMedium = useMediaQuery(theme.breakpoints.down('md'))

    const [fonts, setFonts] = useState<Font[]>([])
    const [isLoadingFonts, setIsLoadingFonts] = useState<boolean>(false)
    const [fontErrorMessage, setFontErrorMessage] = useState<string>('')
    const [fontId, setFontId] = usePersistState<string>('fontId', '')

    const fetchFonts = async () => {
        const _fonts = await getFonts()
        if (_fonts) {
            setFonts(_fonts)
            if (_fonts.length > 0 && fontId === '') {
                // TODO make dynamic from DB
                setFontId(_fonts[3].id)
            } else if (_fonts.length === 0) {
                setFontErrorMessage("No fonts to show")
            }
        }
        else setFontErrorMessage('Failed to load fonts')
        return _fonts
    }

    const loadFonts = async (fonts: Font[]) => {
        let fontFaces = []
        let i = 0, actualFonts = []
        for (const font of fonts) {
            try {
                const fontFace = await new FontFace(font.name, `url(${font.file})`);// @ts-ignore
                (document as any).fonts.add(fontFace)
                fontFaces.push(fontFace)
            } catch (e) { }
        }
        for (const fontFace of fontFaces) {
            try {
                await fontFace.load()
                actualFonts.push(fonts[i])
            } catch (e) { }
            i++
        }
        // Ensure unloaded fonts do not make it into the UI
        setFonts(actualFonts)
    }

    useEffect(() => {
        setIsLoadingFonts(true)
        fetchFonts()
            .then((fonts) => {
                if (fonts) return loadFonts(fonts)
            })
            .then(() => setIsLoadingFonts(false))
    }, [])

    useEffect(() => {
        const selectedFonts = fonts.filter(font => font.id === fontId)
        setFont(selectedFonts.length > 0 ? selectedFonts[0] : DEFAULT_FONT)
    }, [fontId, setFont, fonts])

    const lengthAwareSetMessage = (newMessage: string): string => {
        setMessage(newMessage)
        return newMessage
    }

    return <Box sx={{ display: 'flex', flexDirection: belowMedium ? 'column' : 'row-reverse', alignItems: belowMedium ? 'center' : 'auto', width: belowMedium ? '100%' : 'auto' }} >
        {!belowMedium && <Box sx={{p: 2, pt: 0, width: 250}}/>}

        <Box sx={{ width: belowMedium ? '100%' : 600 }} >
            <Box sx={{ mb: 2 }}>
                <Typography sx={{ textAlign: 'center' }} variant={'h6'}>Back</Typography>
            </Box>
            <Box sx={{ width: '100%', px: 1 }}>
                <MessageAndLocationPostcardBack
                    message={message}
                    setMessage={lengthAwareSetMessage}
                    font={font ? font.name : undefined}
                    color={messageColor ? messageColor.hex : undefined}
                    location={location}
                    setLocation={setLocation}
                    latitude={latitude}
                    setLatitude={setLatitude}
                    longitude={longitude}
                    setLongitude={setLongitude}
                />
            </Box>
        </Box>

        <Box sx={{ p: belowMedium ? 0 : 2, pt: belowMedium ? 3 : 0, width: belowMedium ? '100%' : 250 }}>
            <Typography variant={'h6'}>Personalize your message</Typography>
            <Box sx={{ display: 'flex', justifyContent: 'start', pt: 2, height: belowMedium ? 'auto' : 378, flexDirection: belowMedium ? 'row' : 'column' }}>
                <MessageSettings
                    name={'Fonts'}
                    items={sortFonts(fonts).map(font => ({
                        id: font.id,
                        component: <Box sx={{ height: '24px', width: '24px', display: 'flex', justifyContent: 'center' }}>
                            <Typography sx={{ fontFamily: font.name }}>Aa</Typography>
                        </Box>
                    }))}
                    currentItem={fontId}
                    setCurrentItem={setFontId}
                    isLoading={isLoadingFonts}
                    errorMessage={fontErrorMessage}
                />
            </Box>
        </Box>
    </Box>
}

export default MessageAndLocationStep