import {createContext, Dispatch, SetStateAction} from "react";
import {EMPTY_ADDRESS} from "../../common/postcardAppUtils";
import {v4 as uuid} from "uuid";
import {Address} from "../../../../utils/postcard";
import {CommonContextType, initialCommonContext} from "../../common/utils/commonContextType";

export type CurrentPostcardContextProps = CommonContextType & {
    address: Address,
    setAddress: Dispatch<SetStateAction<Address>> | ((newState: Address) => Address)
    replyBack: boolean,
    setReplyBack: Dispatch<SetStateAction<boolean>> | ((newState: boolean) => boolean)
    chainStartOrContinuation: boolean,
    setChainStartOrContinuation: Dispatch<SetStateAction<boolean>> | ((newState: boolean) => boolean)
    replyAddress: Address,
    setReplyAddress: Dispatch<SetStateAction<Address>> | ((newState: Address) => Address)
    chainName: string,
    setChainName: Dispatch<SetStateAction<string>> | ((newState: string) => string)
}

const EMPTY_FUNC = () => {}

const initialContext: CurrentPostcardContextProps = {
    ...initialCommonContext,
    address: EMPTY_ADDRESS(uuid()), setAddress: EMPTY_FUNC,
    replyBack: false, setReplyBack: EMPTY_FUNC,
    chainStartOrContinuation: false, setChainStartOrContinuation: EMPTY_FUNC,
    replyAddress: EMPTY_ADDRESS(uuid()), setReplyAddress: EMPTY_FUNC,
    chainName: '', setChainName: EMPTY_FUNC,
}

const CurrentPostcardContext = createContext<CurrentPostcardContextProps>(initialContext)

export default CurrentPostcardContext