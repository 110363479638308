import {createContext, Dispatch, SetStateAction} from "react";
import {Address} from "../../../../utils/postcard";
import {CommonContextType, initialCommonContext} from "../../common/utils/commonContextType";

export type GreetingsPostcardContextProps = CommonContextType & {
    addresses: Address[],
    setAddresses: Dispatch<SetStateAction<Address[]>> | ((newState: Address[]) => Address[])
    fileName: string
    setFileName: Dispatch<SetStateAction<string>> | ((newState: string) => string)
}

const EMPTY_FUNC = () => {}

const initialContext: GreetingsPostcardContextProps = {
    ...initialCommonContext,
    addresses: [], setAddresses: EMPTY_FUNC,
    fileName: '', setFileName: EMPTY_FUNC
}

const GreetingsPostcardContext = createContext<GreetingsPostcardContextProps>(initialContext)

export default GreetingsPostcardContext