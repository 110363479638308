import React, {useContext} from "react";
import {PostcardAppInitialState} from "./utils/PostcardAppInitialState";
import CurrentPostcardContext from "./utils/currentPostcardContext";
import {
    Box,
    TextField,
    Typography,
    useMediaQuery,
    useTheme
} from "@mui/material";
import Setting from "./setting/Setting";
import RecipientPostcardBack from "../../postcardrecap/postcard/templates/RecipientPostcardBack";

type RecipientStepProps = {
    price: number,
    state: PostcardAppInitialState
}

const RecipientStep = ({ price, state }: RecipientStepProps) => {
    const {
        address, setAddress, replyBack, setReplyBack, chainStartOrContinuation, setChainStartOrContinuation,
        chainName, setChainName, location, longitude, latitude, message, font, messageColor
    } = useContext(CurrentPostcardContext)

    const theme = useTheme()
    const belowMedium = useMediaQuery(theme.breakpoints.down('md'))

    const _setReplyBack = (value: boolean) => {
        setReplyBack(value)
        setChainStartOrContinuation(false)
    }

    const _setChainStartOrContinuation = (value: boolean) => {
        setChainStartOrContinuation(value)
        setReplyBack(false)
    }

    const textFieldDefaults = theme.components?.MuiTextField?.defaultProps?.sx ? theme.components.MuiTextField.defaultProps.sx : {}

    return <Box sx={{ display: 'flex', flexDirection: 'row-reverse', alignItems: belowMedium ? 'center' : 'auto', width: belowMedium ? '100%' : 'auto' }}>
        {!belowMedium && <Box sx={{p: 2, pt: 0, width: 250}}/>}

        <Box sx={{ width: belowMedium ? '100%' : 600 }} >
            <Box sx={{ mb: 2 }}>
                <Typography sx={{ textAlign: 'center' }} variant={'h6'}>Back</Typography>
            </Box>
            <Box sx={{ width: '100%', px: 1 }}>
                <RecipientPostcardBack
                    message={message}
                    font={font ? font.name : undefined}
                    color={messageColor ? messageColor.hex : undefined}
                    location={location}
                    latitude={latitude}
                    longitude={longitude}
                    address={address}
                    setAddress={setAddress}
                    isGiftQRCode={replyBack || chainStartOrContinuation}
                />
            </Box>
            <Box sx={{ mt: 4 }}>
                <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Typography variant={'h6'}>This postcard</Typography>
                    <Typography variant={'h6'}>{price}€</Typography>
                </Box>
                <Box sx={{ width: '100%', backgroundColor: '#666666', height: '1px', mt: 2 }} />
                <Setting
                    title={'Reply'}
                    message={<>Do you want also a reply back? <br /> Pay in advance your friend's postcard.</>}
                    price={'+2.49€'}
                    value={replyBack}
                    setValue={_setReplyBack}
                />
                <Setting
                    title={'Chain'}
                    message={<>Start a chain of friends around the world.<br /> Everyone will pay the postcard for the next one.</>}
                    price={'+2.49€'}
                    value={chainStartOrContinuation}
                    setValue={_setChainStartOrContinuation}
                />
                {chainStartOrContinuation && <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                    <Typography variant={'h6'}>Name of your chain</Typography>
                    <TextField
                        value={chainName}
                        onChange={e => setChainName(e.target.value)}
                        placeholder={'Chain name'}
                        sx={{ width: '100%', ml: 2, ...textFieldDefaults }}
                    />
                </Box>}
            </Box>
        </Box>

        {!belowMedium && <Box sx={{p: 2, pt: 0, width: 250}}/>}
    </Box>
}

export default RecipientStep