import {Address} from "../../../../utils/postcard";
import {Dispatch, SetStateAction} from "react";
import PostcardTemplate from "./PostcardTemplate";
import QRCodeImage from "../header/QRCodeImage";
import LocationBox from "../header/location/LocationBox";
import MessageBox from "../body/message/MessageBox";
import AddGreetingsAddressesComponent from "../body/address/AddGreetingsAddressesComponent";

type GreetingsRecipientsProps = {
    message: string
    font?: string
    color?: string

    location: string
    latitude: number
    longitude: number

    addresses: Address[]
    setAddresses: Dispatch<SetStateAction<Address[]>> | ((newState: Address[]) => Address[])
    fileName: string
    setFileName: Dispatch<SetStateAction<string>> | ((newState: string) => string)
}

const GreetingsRecipientsPostcardBack = (props: GreetingsRecipientsProps) => {
    const {
        message, font, color,
        location, latitude, longitude,
        addresses, setAddresses, fileName, setFileName
    } = props

    return <PostcardTemplate
        location={<LocationBox
            location={location}
            latitude={latitude}
            longitude={longitude}
        />}
        qrcode={<QRCodeImage isGiftQRCode={false} />}
        message={<MessageBox
            font={font}
            color={color}
            message={message}
        />}
        address={<AddGreetingsAddressesComponent
            addresses={addresses}
            setAddresses={setAddresses}
            fileName={fileName}
            setFileName={setFileName}
        />}
    />
}

export default GreetingsRecipientsPostcardBack