import React, {Dispatch, SetStateAction, useContext, useEffect, useState} from "react";
import AuthenticatorWrapper from "../../postcard-app/AuthenticatorWrapper";
import {Accordion, AccordionDetails, AccordionSummary, Box, Typography, useMediaQuery, useTheme} from "@mui/material";
import GreetingsPostcardContext from "../utils/greetingsPostcardContext";
import Checkout from "../../common/Checkout";
import GreetingsCartPostcardRecap from "../../../postcardrecap/cartpostcardrecap/GreetingsCartPostcardRecap";
import {getGreetingsPromotion, getPrice} from "../../common/postcardAppUtils";
import {PostcardAppInitialState} from "../../postcard-app/utils/PostcardAppInitialState";

type CartStepProps = {
    onSubmitOrder: () => Promise<void>
    onSubmitBankTransfer: () => Promise<void>
}

const InnerCartStep = ({ onSubmitOrder, onSubmitBankTransfer }: CartStepProps) => {
    const {
        id, images, message, font,
        messageColor, location, longitude, latitude,
        layout, addresses, setPromotion } = useContext(GreetingsPostcardContext)

    const [isAccordionOpen, setIsAccordionOpen] = useState<boolean>(false)

    const theme = useTheme()
    const belowMedium = useMediaQuery(theme.breakpoints.down('md'))

    const basePrice = getPrice(PostcardAppInitialState.DEFAULT)
    const totalPrice = basePrice * addresses.length

    const postcardsCommonData = {
        id, images, message, font, messageColor, layout, location, latitude, longitude, price: basePrice
    }

    const lastAddresses = addresses.slice(1)
    const firstAddress = addresses[0]

    useEffect(() => {
        const postcardsQuantity = addresses.length
        console.log("Promotion: ", getGreetingsPromotion(postcardsQuantity))
        setPromotion(getGreetingsPromotion(postcardsQuantity))
    }, [])

    return <Box sx={{ display: 'flex', width: '100%', flexDirection: 'column' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'start', gap: 0.5, mb: 2 }}>
            <Typography variant={'h6'}>Shopping cart</Typography>
            <Typography variant={'body1'}>You have {addresses.length} item{addresses.length === 1 ? '' : 's'} in your cart</Typography>
        </Box>

        <Box sx={{ display: 'flex', flexDirection: belowMedium ? 'column' : 'row', alignItems: 'start', width: '100%' }}>
            <Box sx={{ width: belowMedium ? '100%' : 800, mr: belowMedium ? 0 : 4, flexShrink: 0 }}>
                {lastAddresses.length > 0 && <Accordion
                    sx={{boxShadow: 'none', backgroundColor: 'transparent'}}
                    onChange={(e, expanded) => setIsAccordionOpen(expanded)}
                >
                    <AccordionSummary
                        expandIcon={<img alt={'Expand'} src={isAccordionOpen ? 'icons/profile/open_accordion_arrow.svg' : 'icons/profile/accordion_arrow.svg'} draggable={false}/>}
                        sx={{
                            backgroundColor: 'white',
                            py: 1,
                            px: 1.5,
                            mb: 2,
                            borderRadius: 29,
                            '& .MuiAccordionSummary-content': {
                                margin: '12px 0 !important'
                            },
                            boxShadow: '0 0 3.5px rgba(0, 0, 0, 0.25)'
                        }}>
                        <Typography
                            sx={{color: isAccordionOpen ? theme.palette.primary.main : "#666666", fontSize: "24px", fontWeight: 500, px: 2, textAlign: 'left'}}>See the other postcards (x{addresses.length - 1})</Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{ p: 0 }}>
                        {lastAddresses.map(address => <GreetingsCartPostcardRecap
                            {...postcardsCommonData}
                            address={address}
                            key={address.id}
                        />)}
                    </AccordionDetails>
                </Accordion>}
                <GreetingsCartPostcardRecap
                    {...postcardsCommonData}
                    address={firstAddress}
                />
            </Box>

            <Checkout
                totalPrice={totalPrice}
                onSubmitOrder={onSubmitOrder}
                isAddAnotherPostcard={false}
                isPromotionEnabled={false}
                bankTransferOption={true}
                onSubmitBankTransfer={onSubmitBankTransfer}
                isGreetings={true}
            />
        </Box>
    </Box>
}

const CartStep = (props: CartStepProps &
    { authState: boolean, setAuthState: Dispatch<SetStateAction<boolean>> }
) => {
    return <AuthenticatorWrapper authState={props.authState} setAuthState={props.setAuthState}>
        <InnerCartStep {...props} />
    </AuthenticatorWrapper>
}

export default CartStep