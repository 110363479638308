import {InvokeCommand, LambdaClient, LogType} from '@aws-sdk/client-lambda'
import awsmobile from "../aws-exports-copy";
import {fetchAuthSession} from "aws-amplify/auth";


const getBackendEnvironment = (): string => {
    if (!awsmobile.aws_user_files_s3_bucket) return ''
    const splits = awsmobile.aws_user_files_s3_bucket.split('-')
    return splits[splits.length - 1]
}

const invoke = async (funcName: string, payload: object) => {
    const session = await fetchAuthSession()
    const client = new LambdaClient({
        region: awsmobile.aws_project_region,
        credentials: session.credentials
    })
    const command = new InvokeCommand({
        FunctionName: funcName,
        Payload: JSON.stringify(payload),
        LogType: LogType.Tail,
    })

    const { Payload } = await client.send(command)
    return JSON.parse(Payload?.transformToString('utf-8') as string)
};

const getLocation = (window: Window): string => {
    return window.location.protocol + '//' + window.location.hostname +
        (window.location.hostname === 'localhost' ? (':' + window.location.port) : '')
}

const getCoordsLabel = (latitude: number, longitude: number): string => {
    const latitudePoint = latitude > 0 ? 'N' : 'S', longitudePoint = longitude > 0 ? 'E' : 'W';
    const absLatitude = latitude > 0 ? latitude : latitude * -1, absLongitude = longitude > 0 ? longitude : longitude * -1
    return `${Math.round(absLatitude * 1000) / 1000}° ${latitudePoint}, ${Math.round(absLongitude * 1000) / 1000}° ${longitudePoint}`
}

export { getBackendEnvironment, invoke, getLocation, getCoordsLabel }