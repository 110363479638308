import {Box, Typography} from "@mui/material";
import {getCoordsLabel} from "../../../../../utils/utils";
import {useContext} from "react";
import {PostcardWidthContext} from "../../templates/PostcardTemplate";

type LocationBoxProps = {
    location: string
    latitude: number
    longitude: number
    isClickable?: boolean
    onClick?: () => void
    postcardWidth?: number
}

const LocationBox = ({ location, latitude, longitude, isClickable, onClick, postcardWidth }: LocationBoxProps) => {

    const clickableHover = {
        boxShadow: '0px 0px 4.2963px rgba(0, 0, 0, 0.25)',
        cursor: 'pointer'
    }
    const unclickableHover = {
        boxShadow: null,
        cursor: 'auto'
    }

    const { width } = useContext(PostcardWidthContext)
    if (!postcardWidth) postcardWidth = width

    return <Box sx={{
        ml: '2.82%',
        minWidth: '22.40%',
        maxWidth: '38%',
        top: 0,
        position: 'absolute',
        border: '0.5px solid black',
        borderRadius:'6.30%/20.51%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        '&:hover': isClickable ? clickableHover : unclickableHover,
        py: postcardWidth * 0.0123 + 'px'
    }}>
        <Box
            sx={{ height: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1, px: 1 }}
            onClick={isClickable ? onClick : undefined}
        >
            <img style={{ aspectRatio: 1, flexShrink: 0, height: postcardWidth * 0.0388 }} alt={'Logo'} src={'icons/location.svg'}/>
            <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                <Typography sx={{ fontSize: postcardWidth * 0.0182, textAlign: 'left' }}>{location}</Typography>
                <Typography sx={{ fontSize: postcardWidth * 0.0134, letterSpacing: 0.04} }>{getCoordsLabel(latitude, longitude)}</Typography>
            </Box>
        </Box>
    </Box>
}

export default LocationBox