import React, {useContext} from "react";
import {
    Box,
    Typography,
    useMediaQuery,
    useTheme
} from "@mui/material";
import GreetingsPostcardContext from "./utils/greetingsPostcardContext";
import GreetingsRecipientsPostcardBack from "../../postcardrecap/postcard/templates/GreetingsRecipientsPostcardBack";

const RecipientStep = () => {
    const {
        addresses, setAddresses,
        location, longitude, latitude, message,
        font, messageColor,
        fileName, setFileName
    } = useContext(GreetingsPostcardContext)

    const theme = useTheme()
    const belowMedium = useMediaQuery(theme.breakpoints.down('md'))

    return <Box sx={{ display: 'flex', flexDirection: 'row-reverse', alignItems: belowMedium ? 'center' : 'auto', width: belowMedium ? '100%' : 'auto' }}>
        {!belowMedium && <Box sx={{p: 2, pt: 0, width: 250}}/>}

        <Box sx={{ width: belowMedium ? '100%' : 600 }} >
            <Box sx={{ mb: 2 }}>
                <Typography sx={{ textAlign: 'center' }} variant={'h6'}>Back</Typography>
            </Box>
            <Box sx={{ width: '100%', px: 1 }}>
                <GreetingsRecipientsPostcardBack
                    message={message}
                    location={location}
                    latitude={latitude}
                    longitude={longitude}
                    addresses={addresses}
                    setAddresses={setAddresses}
                    fileName={fileName}
                    setFileName={setFileName}
                />
            </Box>
        </Box>

        {!belowMedium && <Box sx={{p: 2, pt: 0, width: 250}}/>}
    </Box>
}

export default RecipientStep