import {
    Box,
    Typography,
    TextField,
    useTheme
} from "@mui/material";
import {useEffect, useRef, useState} from "react";
import AddressTextField from "../AddressTextField";
import LocationBox from "./postcard/header/location/LocationBox";
import {PostcardBackProps} from "./postcard/PostcardBack";
import EditLocationBox from "./postcard/header/location/EditLocationBox";
import LocationAutocomplete from "./postcard/header/location/LocationAutocomplete";
import QRCodeImage from "./postcard/header/QRCodeImage";
import StampImage from "./postcard/header/StampImage";
import LogoBox from "./postcard/body/LogoBox";
import StampBox from "./postcard/body/StampBox";
import EditMessageBox from "./postcard/body/message/EditMessageBox";
import EditAddressBox from "./postcard/body/address/EditAddressBox";

const PostcardBackLarge = ({ locationTextField, messageTextField, recipientSpace, recipientTextField, location, ...props }: PostcardBackProps) => {
    const [width, setWidth] = useState<number>(0)
    const container = useRef<HTMLDivElement>()

    const [editMessage, setEditMessage] = useState<boolean>(false)
    const [editLocation, setEditLocation] = useState<boolean>(false)
    const [editAddress, setEditAddress] = useState<boolean>(false)

    const theme = useTheme();

    useEffect(() => {
        if (container.current)
            setWidth(container.current?.offsetWidth)
    }, [container])

    const onClickEditLocationBox = () => setEditLocation(true)
    const onClickEditMessageBox = () => setEditMessage(true)
    const onClickEditAddressBox = () => setEditAddress(true)

    // @ts-ignore
    const isEmptyAddress = (): boolean => !props.address.streetAddress && !props.address.fullName && !props.address.city && !props.address.zipCode

    return <Box sx={{ width: '100%', aspectRatio: 15/10, display: 'flex', flexDirection: 'column', pt: '2.82%', backgroundColor: 'white', boxShadow: '0px 0px 4.2963px rgba(0, 0, 0, 0.25)', boxSizing: 'border-box' }} ref={container}>
        <Box sx={{ width: '100%', height: '18.78%', position: 'relative' }}>
            {!locationTextField && <LocationBox
                location={location}
                latitude={props.latitude}
                longitude={props.longitude}
                postcardWidth={width}
            />}
            {locationTextField && !editLocation && !location ? <EditLocationBox
                onClick={onClickEditLocationBox}
                isSmall={false}
                postcardWidth={width}
            /> : locationTextField && <LocationAutocomplete
                location={location}
                // @ts-ignore
                setLocation={props.setLocation} setLatitude={props.setLatitude} setLongitude={props.setLongitude}
                setEditLocation={setEditLocation}
                isSmall={false}
            />}
            {/* @ts-ignore */}
            <QRCodeImage isGiftQRCode={props.isGiftQRCode} postcardWidth={width} />
            <StampImage />
        </Box>
        <Box sx={{ width: '100%', height: '3.22%' }} />
        <Box sx={{ width: '100%', height: 'calc(62.25% - 10.50%)', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <Box sx={{ pl: '5.64%', width: '47.88%', pr: messageTextField ? '0%' : '5.64%' }}>
                {messageTextField ? (!editMessage && !props.message ? <EditMessageBox
                        onClick={onClickEditMessageBox}
                        isSmall={false}
                        postcardWidth={width}
                    /> : <TextField multiline sx={{
                    width: 'calc(100% - 40px)',
                    height: '100%',
                    '& .MuiInputBase-root': {
                        height: '100% !important',
                        alignItems: 'start',
                        borderRadius: 2
                    },
                    '& textarea': {
                        // @ts-ignore
                        fontFamily: props.font ? props.font : 'auto',
                        // @ts-ignore
                        color: props.color ? props.color : 'auto',
                    },
                }}
                value={props.message}
                // @ts-ignore
                onChange={e => props.setMessage(e.target.value)} label={'Message'} />) :
                    <Typography sx={{
                        fontSize: width * 0.0292,
                        pt: width * 0.0282 / 8,
                        // @ts-ignore
                        fontFamily: props.font ? props.font : 'auto',
                        // @ts-ignore
                        color: props.color ? props.color : 'auto',
                        whiteSpace: 'pre-wrap'
                    }}>
                        {props.message}
                    </Typography>
                }
            </Box>
            <LogoBox postcardWidth={width} />
            <Box sx={{ display: 'flex', flexDirection: 'column', width: '47.27%' }}>
                <StampBox postcardWidth={width} />
                <Box sx={{ height: '54.50%', pl: '22.01%', pt: '7.83%', pr: '21.27%', pb: '3.36%' }}>
                    <Box sx={{ height: '100%', width: '100%' }}>
                        {recipientSpace ? <>
                            {/* @ts-ignore */}
                        </> : (recipientTextField ? (!editAddress && isEmptyAddress() ? <EditAddressBox
                                onClick={onClickEditAddressBox}
                                postcardWidth={width}
                            /> : <Box sx={{
                                position: 'absolute',
                                width: '180px',
                                height: '140px',
                                border: '0.5px solid ' + theme.palette.primary.main,
                                borderRadius: 2,
                                px: 1,
                                py: 0.5,
                                display: 'flex',
                                flexDirection: 'column'
                            }}>
                                {/* @ts-ignore */}
                                <AddressTextField placeholder={'FULL NAME'} value={props.address.fullName} onChange={e => props.setAddress({ ...props.address, fullName: e.target.value })} small={true} />
                                {/* @ts-ignore */}
                                <AddressTextField placeholder={'ADDRESS'} value={props.address.streetAddress} onChange={e => props.setAddress({ ...props.address, streetAddress: e.target.value })} small={true} />
                                {/* @ts-ignore */}
                                <AddressTextField placeholder={'CITY, COUNTY'} value={props.address.city} onChange={e => props.setAddress({ ...props.address, city: e.target.value })} small={true} />
                                {/* @ts-ignore */}
                                <AddressTextField placeholder={'ZIP CODE'} value={props.address.zipCode} onChange={e => props.setAddress({ ...props.address, zipCode: e.target.value })} small={true} />
                            </Box>) :
                            <Typography sx={{ fontFamily: 'Poste', fontSize: width * 0.0176, textTransform: 'uppercase' }}>
                                {/* @ts-ignore */}
                                {props.address.fullName} <br />
                                {/* @ts-ignore */}
                                {props.address.streetAddress} <br />
                                {/* @ts-ignore */}
                                {props.address.city} <br />
                                {/* @ts-ignore */}
                                {props.address.zipCode} <br />
                            </Typography>)}
                    </Box>
                </Box>
            </Box>
        </Box>
        <Box sx={{ width: '100%', height: 'calc(15.75% + 10.50%)' }} />
    </Box>
}

export default PostcardBackLarge