import {createContext} from "react";

export type NextDisabledContextProps = {
    isNextDisabled: boolean
    updateIsNextDisabled: () => void
}

const initialContext: NextDisabledContextProps = {
    isNextDisabled: true,
    updateIsNextDisabled: () => {}
}

const GreetingsNextDisabledContext = createContext<NextDisabledContextProps>(initialContext)

export default GreetingsNextDisabledContext