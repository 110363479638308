import {Address} from "../../../../utils/postcard";
import {Dispatch, SetStateAction} from "react";
import {useMediaQuery, useTheme} from "@mui/material";
import {EditAddressProps} from "../body/address/address";
import PostcardTemplate from "./PostcardTemplate";
import QRCodeImage from "../header/QRCodeImage";
import LocationBox from "../header/location/LocationBox";
import MessageBox from "../body/message/MessageBox";
import EditAddressComponentSmall from "../body/address/EditAddressComponentSmall";
import EditAddressComponentLarge from "../body/address/EditAddressComponentLarge";

type RecipientProps = {
    message: string
    font?: string
    color?: string

    location: string
    latitude: number
    longitude: number

    address: Address
    setAddress: Dispatch<SetStateAction<Address>> | ((newState: Address) => Address)

    isGiftQRCode: boolean
}

const RecipientPostcardBack = (props: RecipientProps) => {
    const {
        message, font, color,
        location, latitude, longitude,
        address, setAddress, isGiftQRCode
    } = props

    const theme = useTheme();
    const belowMedium = useMediaQuery(theme.breakpoints.down('md'))

    const editAddressProps: EditAddressProps = {
        address, setAddress
    }

    return <PostcardTemplate
        location={<LocationBox
            location={location}
            latitude={latitude}
            longitude={longitude}
        />}
        qrcode={<QRCodeImage isGiftQRCode={isGiftQRCode} />}
        message={<MessageBox
            font={font}
            color={color}
            message={message}
        />}
        address={belowMedium ?
            <EditAddressComponentSmall {...editAddressProps} /> :
            <EditAddressComponentLarge {...editAddressProps} />
        }
    />
}

export default RecipientPostcardBack