
export const createPostcard = /* GraphQL */ `
    mutation CreatePostcard(
        $input: CreatePostcardInput!
    ) {
        createPostcard(input: $input) {
            id
        }
    }
`;

export const createImage = /* GraphQL */ `
    mutation CreateImage(
        $input: CreateImageInput!
    ) {
        createImage(input: $input) {
            id
        }
    }
`;

export const createOrder = /* GraphQL */ `
    mutation CreateOrder(
        $input: CreateOrderInput!
    ) {
        createOrder(input: $input) {
            id
        }
    }
`;

export const createOrderLineItem = /* GraphQL */ `
    mutation CreateOrderLineItem(
        $input: CreateOrderLineItemInput!
    ) {
        createOrderLineItem(input: $input) {
            id
        }
    }
`;

export const listFrames = /* GraphQL */ `
    query ListPostcardFrames(
        $limit: Int
        $nextToken: String
    ) {
        listPostcardFrames(limit: $limit, nextToken: $nextToken) {
            items {
                id
                name
            }
        }
    }
`;

export const listFonts = /* GraphQL */ `
    query ListPostcardFonts(
        $limit: Int
        $nextToken: String
    ) {
        listPostcardFonts(limit: $limit, nextToken: $nextToken) {
            items {
                id
                name
                extension
            }
        }
    }
`;

export const listMessageColors = /* GraphQL */ `
    query ListPostcardMessageColors(
        $limit: Int
        $nextToken: String
    ) {
        listPostcardMessageColors(limit: $limit, nextToken: $nextToken) {
            items {
                id
                name
                hex
            }
        }
    }
`;

export const listPostcards = /* GraphQL */ `
    query ListPostcards(
        $filter: ModelPostcardFilterInput
        $nextToken: String
    ) {
        listPostcards(filter: $filter, limit: 99, nextToken: $nextToken) {
            items {
                id
                message
                recipientAddress {
                    id
                    fullName
                    streetAddress
                    city
                    zipCode
                }
                location
                latitude
                longitude
                layout
                createdAt
                images {
                    items {
                        id
                        index
                        path
                    }
                }
                frame {
                    id
                    name
                }
                font {
                    id
                    name
                    extension
                }
                messageColor {
                    id
                    name
                    hex
                }
                settings {
                    id
                    replyBackSetting
                    replyAddress {
                        id
                        fullName
                        streetAddress
                        city
                        zipCode
                    }
                    postcardSettingsReplyId
                    chainSetting
                    chain {
                        id
                        name
                    }
                }
            }
        }
    }
`;

export const listPostcardsFromOrders = /* GraphQL */ `query ListOrders(
    $filter: ModelOrderFilterInput
    $limit: Int
    $nextToken: String
) {
    listOrders(filter: $filter, limit: $limit, nextToken: $nextToken) {
        items {
            id
            owner
            orderLineItems {
                items {
                    id
                    postcard {
                        id
                        message
                        recipientAddress {
                            id
                            fullName
                            streetAddress
                            city
                            zipCode
                        }
                        location
                        latitude
                        longitude
                        layout
                        createdAt
                        images {
                            items {
                                id
                                index
                                path
                            }
                        }
                        frame {
                            id
                            name
                        }
                        font {
                            id
                            name
                            extension
                        }
                        messageColor {
                            id
                            name
                            hex
                        }
                        settings {
                            id
                            replyBackSetting
                            replyAddress {
                                id
                                fullName
                                streetAddress
                                city
                                zipCode
                            }
                            postcardSettingsReplyId
                            chainSetting
                            chain {
                                id
                                name
                            }
                        }
                    }
                }
            }
        }
    }
}
`

export const listPostcardsForCount = /* GraphQL */ `
    query ListPostcards(
        $filter: ModelPostcardFilterInput
        $nextToken: String
    ) {
        listPostcards(filter: $filter, limit: 99, nextToken: $nextToken) {
            items {
                id
            }
        }
    }
`;

export const getPostcard = /* GraphQL */ `
    query GetPostcard($id: ID!) {
        getPostcard(id: $id) {
            id
            message
            recipientAddress {
                id
                fullName
                streetAddress
                city
                zipCode
            }
            recipientId
            location
            latitude
            longitude
            scanned
            images {
                items {
                    id
                    index
                    path
                }
            }
            layout
            frame {
                id
                name
            }
            font {
                id
                name
                extension
            }
            messageColor {
                id
                name
                hex
            }
            settings {
                id
                replyBackSetting
                replyAddress {
                    id
                    fullName
                    streetAddress
                    city
                    zipCode
                }
                chainSetting
                postcardSettingsReplyId
                chain {
                    id
                    name
                }
            }
            owner
            createdAt
        }
    }
`;

export const getGreetingsPostcardForScan = /* GraphQL */ `
    query GetGreetingsPostcard($id: ID!) {
        getGreetingsPostcard(id: $id) {
            id
            message
            location
            latitude
            longitude
            images {
                items {
                    id
                    index
                    path
                }
            }
            layout
            frame {
                id
                name
            }
            font {
                id
                name
                extension
            }
            messageColor {
                id
                name
                hex
            }
            owner
            createdAt
        }
    }
`;

export const getPostcardGuestUser = /* GraphQL */ `
    query GetPostcard($id: ID!) {
        getPostcard(id: $id) {
            id
            message
            recipientAddress {
                id
                fullName
            }
            recipientId
            location
            latitude
            longitude
            scanned
            images {
                items {
                    id
                    index
                    path
                }
            }
            layout
            frame {
                id
                name
            }
            font {
                id
                name
                extension
            }
            messageColor {
                id
                name
                hex
            }
            settings {
                id
                replyBackSetting
                replyAddress {
                    id
                    fullName
                    streetAddress
                    city
                    zipCode
                }
                chainSetting
                postcardSettingsReplyId
                chain {
                    id
                    name
                }
            }
            owner
            createdAt
        }
    }
`;

export const addQRCodeScan = /* GraphQL */ `
    mutation CreateQRCodeScan(
          $input: CreateQRCodeScanInput!
          $condition: ModelQRCodeScanConditionInput
    ) {
          createQRCodeScan(input: $input, condition: $condition) {
                id
          }
    }
`;

export const updatePostcard = /* GraphQL */ `mutation UpdatePostcard(
    $input: UpdatePostcardInput!
    $condition: ModelPostcardConditionInput
) {
    updatePostcard(input: $input, condition: $condition) {
        id
    }
}
`

export const createPostcardSettings = /* GraphQL */ `mutation CreatePostcardSettings(
    $input: CreatePostcardSettingsInput!
    $condition: ModelPostcardSettingsConditionInput
) {
    createPostcardSettings(input: $input, condition: $condition) {
        id
    }
}
`

export const updatePostcardSettings = /* GraphQL */ `mutation UpdatePostcardSettings(
  $input: UpdatePostcardSettingsInput!
  $condition: ModelPostcardSettingsConditionInput
) {
  updatePostcardSettings(input: $input, condition: $condition) {
    id
  }
}
`

export const createChain = /* GraphQL */ `mutation CreateChain(
    $input: CreateChainInput!
    $condition: ModelChainConditionInput
) {
    createChain(input: $input, condition: $condition) {
        id
    }
}
`

export const getChainForProgression = /* GraphQL */ `query GetChain($id: ID!) {
    getChain(id: $id) {
        id
        name
        postcards {
            items {
                id
                postcardSettingsReplyId
                postcard {
                    id
                    message
                    location
                }
            }
        }
    }
}
`

export const listPromoCodes = /* GraphQL */ `query ListPromoCodes(
    $filter: ModelPromoCodeFilterInput
    $limit: Int
    $nextToken: String
) {
    listPromoCodes(filter: $filter, limit: $limit, nextToken: $nextToken) {
        items {
            id
            name
            active
            orderPromoCode {
                items {
                    id
                    redeemed
                    order {
                        id
                        orderLineItems {
                            items {
                                id
                                postcard {
                                    id
                                }
                            }
                        }
                    }
                }
            }
            owner
        }
    }
}
`

export const listPromoCodesForValidation = /* GraphQL */ `query ListPromoCodes(
    $filter: ModelPromoCodeFilterInput
    $limit: Int
    $nextToken: String
) {
    listPromoCodes(filter: $filter, limit: $limit, nextToken: $nextToken) {
        items {
            id
            active
        }
    }
}
`

export const listOrdersForPromoCodes = /* GraphQL */ `query ListOrders(
    $filter: ModelOrderFilterInput
    $limit: Int
    $nextToken: String
) {
    listOrders(filter: $filter, limit: $limit, nextToken: $nextToken) {
        items {
            id
            orderPromoCode {
                promoCodeOrderPromoCodeId
            }
        }
    }
}
`

export const createOrderPromoCode = /* GraphQL */ `mutation CreateOrderPromoCode(
    $input: CreateOrderPromoCodeInput!
    $condition: ModelOrderPromoCodeConditionInput
) {
    createOrderPromoCode(input: $input, condition: $condition) {
        id
    }
}
`

export const listOrderLineItems = /* GraphQL */ `query ListOrderLineItems(
    $filter: ModelOrderLineItemFilterInput
    $limit: Int
    $nextToken: String
) {
    listOrderLineItems(filter: $filter, limit: $limit, nextToken: $nextToken) {
        items {
            id
            orderLineItemPostcardId
        }
    }
}
`

export const createAddress = /* GraphQL */ `mutation CreateAddress(
    $input: CreateAddressInput!
    $condition: ModelAddressConditionInput
) {
    createAddress(input: $input, condition: $condition) {
        id
    }
}
`

export const getPostcardForPricing = /* GraphQL */ `
    query GetPostcard($id: ID!) {
        getPostcard(id: $id) {
            id
            settings {
                initialState
                replyBackSetting
                chainSetting
            }
        }
    }
`;

export const listOrderLineItemsAndPostcard = /* GraphQL */ `query ListOrderLineItems(
    $filter: ModelOrderLineItemFilterInput
    $nextToken: String
) {
    listOrderLineItems(filter: $filter, limit: 999, nextToken: $nextToken) {
        items {
            id
            processed
            createdAt
            postcard {
                id
                message
                recipientAddress {
                    id
                    fullName
                    streetAddress
                    city
                    zipCode
                }
                location
                latitude
                longitude
                layout
                createdAt
                images {
                    items {
                        id
                        index
                        path
                    }
                }
                frame {
                    id
                    name
                }
                font {
                    id
                    name
                    extension
                }
                messageColor {
                    id
                    name
                    hex
                }
                settings {
                    id
                    replyBackSetting
                    replyAddress {
                        id
                        fullName
                        streetAddress
                        city
                        zipCode
                    }
                    postcardSettingsReplyId
                    chainSetting
                    chain {
                        id
                        name
                    }
                }
            }
        }
        nextToken
        __typename
    }
}
`

export const listOrderLineItemsAndGreetingsPostcard = /* GraphQL */ `query ListOrderLineItems(
    $filter: ModelOrderLineItemFilterInput
    $nextToken: String
) {
    listOrderLineItems(filter: $filter, limit: 999, nextToken: $nextToken) {
        items {
            id
            processed
            createdAt
            greetingsPostcard {
                id
                message
                recipientAddresses {
                    items {
                        id
                        fullName
                        streetAddress
                        city
                        zipCode
                    }
                }
                location
                latitude
                longitude
                layout
                createdAt
                images {
                    items {
                        id
                        index
                        path
                    }
                }
                frame {
                    id
                    name
                }
                font {
                    id
                    name
                    extension
                }
                messageColor {
                    id
                    name
                    hex
                }
            }
        }
        nextToken
        __typename
    }
}
`

export const createGreetingsAddress = /* GraphQL */ `mutation CreateGreetingsAddress(
    $input: CreateGreetingsAddressInput!
    $condition: ModelGreetingsAddressConditionInput
) {
    createGreetingsAddress(input: $input, condition: $condition) {
        id
    }
}
`

export const createGreetingsPostcard = /* GraphQL */ `mutation CreateGreetingsPostcard(
    $input: CreateGreetingsPostcardInput!
    $condition: ModelGreetingsPostcardConditionInput
) {
    createGreetingsPostcard(input: $input, condition: $condition) {
        id
    }
}
`