import {Box, Button, Card, CardContent, TextField, Typography, useTheme} from "@mui/material";
import React, {useContext, useState} from "react";
import CurrentPostcardContext from "../postcard-app/utils/currentPostcardContext";
import {getCurrentUser} from "aws-amplify/auth";
import {isPromoCodeValid as _isPromoCodeValid} from "../../../api/api";
import {
    AMBASSADOR_NEWS_LETTER_PROMO_CODE,
    AMBASSADOR_PROMO_CODE,
    GREETINGS_20_PROMO_CODE,
    LAUNCH_PROMO_CODE
} from "../../../api/apiUtils";
import GreetingsPostcardContext from "../greetings-postcard-app/utils/greetingsPostcardContext";
import {CommonContextType} from "./utils/commonContextType";

type CheckoutProps = {
    totalPrice: number
    onSubmitOrder: () => Promise<void>,
    isAddAnotherPostcard: boolean
    isPromotionEnabled: boolean
    onAddAnotherPostcard?: () => void,
    bankTransferOption?: boolean
    onSubmitBankTransfer?: () => Promise<void>
    isGreetings?: boolean
}

const Checkout = ({ totalPrice, onSubmitOrder, isAddAnotherPostcard, isPromotionEnabled, onAddAnotherPostcard, bankTransferOption, onSubmitBankTransfer, isGreetings }: CheckoutProps) => {
    const [promoCodeName, setPromoCodeName] = useState<string>('')
    const [isPromoCodeValid, setIsPromoCodeValid] = useState<boolean | null>(null)
    const [isLoadingPromoCodeValidation, setIsLoadingPromoCodeValidation] = useState<boolean>(false)
    const [errorMessage, setErrorMessage] = useState<string>('')

    const context = (isGreetings ? GreetingsPostcardContext : CurrentPostcardContext) as unknown as React.Context<CommonContextType>

    const { setPromotion, promotion, setPromoCodeId } = useContext(context)

    const theme = useTheme()

    console.log(promotion)

    const validatePromoCode = async () => {
        setErrorMessage('')
        setIsPromoCodeValid(null)
        setIsLoadingPromoCodeValidation(true)
        const { userId } = await getCurrentUser()
        const result = await _isPromoCodeValid(promoCodeName, userId)
        if (result === null) {
            setErrorMessage('Unable to validate promo code')
            setPromotion(1.0)
            setPromoCodeId(undefined)
        } else {
            setIsPromoCodeValid(result !== false)
            setPromotion(result !== false ? (promoCodeName === GREETINGS_20_PROMO_CODE ? 0.80 : (promoCodeName === LAUNCH_PROMO_CODE ? 0.50 : (promoCodeName === AMBASSADOR_PROMO_CODE || promoCodeName === AMBASSADOR_NEWS_LETTER_PROMO_CODE ? 0.0 : 0.5))) : 1.0)
            setPromoCodeId(result !== false ? result.id : undefined)
        }
        setIsLoadingPromoCodeValidation(false)
    }

    const textFieldDefaults = theme.components?.MuiTextField?.defaultProps?.sx ? theme.components.MuiTextField.defaultProps.sx : {}
    const buttonDefaults = theme.components?.MuiButton?.defaultProps?.sx ? theme.components.MuiButton.defaultProps.sx : {}

    return <Box sx={{ width: '100%' }}>
        <Card sx={{ width: '100%', mb: 2 }} className={'cart-postcard-recap'}>
            <CardContent sx={{ p: 3 }}>
                <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', mb: 2 }}>
                    <Typography variant={'body1'}>Subtotal</Typography>
                    <Typography variant={'body1'} sx={{ color: 'black' }}>{ Math.floor(totalPrice * 100) / 100 }€</Typography>
                </Box>
                <Box sx={{width: '100%', display: 'flex', justifyContent: 'space-between', mb: 3}}>
                    <Typography variant={'body1'}>Discount</Typography>
                    <Typography variant={'body1'} sx={{
                        color: 'black',
                        fontWeight: promotion !== 1 ? 'bold' : 'normal'
                    }}>{Math.floor((totalPrice * (1 - promotion)) * 100) / 100}€ {promotion !== 1 ? ('(' + (Math.floor((1 - promotion + 0.00000000000001) * 100)).toString() + '%)') : ''}</Typography>
                </Box>
                {isPromotionEnabled && <Box
                    sx={{width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3}}>
                    <TextField
                        value={promoCodeName}
                        onChange={e => setPromoCodeName(e.target.value)}
                        placeholder={'Coupon code'}
                        sx={{width: '100%', mr: 2, ...textFieldDefaults}}
                    />
                    <Button onClick={validatePromoCode} variant={'contained'} sx={{flexShrink: 0, ...buttonDefaults}}>
                        Apply coupon
                    </Button>
                </Box>}
                <Box sx={{ width: '100%', display: 'flex', justifyContent: 'stretch', mb: 2 }}>
                    <Button onClick={onSubmitOrder} variant={'contained'} sx={{ width: '100%', ...buttonDefaults }}>
                        Checkout | { Math.floor((totalPrice * promotion) * 100) / 100 }€
                    </Button>
                </Box>
                {bankTransferOption && <Box sx={{width: '100%', display: 'flex', justifyContent: 'stretch', mb: 2}}>
                    <Button onClick={onSubmitBankTransfer} variant={'contained'} color={"secondary"} sx={{width: '100%', ...buttonDefaults}}>
                        Pay later with bank transfer
                    </Button>
                </Box>}
                {isAddAnotherPostcard && <Box sx={{width: '100%', display: 'flex', justifyContent: 'space-between', mb: 2}}>
                    <Button onClick={onAddAnotherPostcard} variant={"contained"} color={'secondary'}
                            sx={{width: '100%', ...buttonDefaults}}>
                        <span>Add another postcard</span>
                    </Button>
                </Box>}
            </CardContent>
        </Card>
        {isLoadingPromoCodeValidation && <Typography>Loading...</Typography>}
        {errorMessage && <Typography>{errorMessage}</Typography>}
        {isPromoCodeValid !== null && <Typography>{isPromoCodeValid ? 'Valid' : 'Invalid'}</Typography>}
    </Box>
}

export default Checkout