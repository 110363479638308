import usePersistState, {usePersistImages, usePersistStateLayout} from "../utils/usePersistState";
import {Address, Font, Frame, MessageColor} from "../utils/postcard";
import {useEffect} from "react";
import GreetingsPostcardContext from "../components/app/greetings-postcard-app/utils/greetingsPostcardContext";
import InnerGreetingsPostcardApp from "../components/app/greetings-postcard-app/InnerGreetingsPostcardApp";
import {
    DEFAULT_COLOR,
    DEFAULT_FONT,
    DEFAULT_LAYOUT,
    NO_COORDINATE,
    NO_FRAME
} from "../components/app/common/postcardAppUtils";

const CACHE_DATA = {
    storeName: '_greetings_images_metadata',
    cacheName: 'greetings-images-cache'
}

const GreetingsAppPage = () => {
    const [id, setId] = usePersistState<string>('greetings_id', '')
    const [images, setImages, handleCacheImage, handleLoadCachedImages] = usePersistImages(CACHE_DATA)
    const [message, setMessage] = usePersistState<string>('greetings_message', '')
    const [location, setLocation] = usePersistState<string>('greetings_location', '')
    const [latitude, setLatitude] = usePersistState<number>('greetings_latitude', NO_COORDINATE)
    const [longitude, setLongitude] = usePersistState<number>('greetings_longitude', NO_COORDINATE)
    const [addresses, setAddresses] = usePersistState<Address[]>('greetings_addresses', [])
    const [fileName, setFileName] = usePersistState<string>('greetings_file_name', '')
    const [frame, setFrame] = usePersistState<Frame | undefined>('greetings_frame', NO_FRAME)
    const [layout, setLayout] = usePersistStateLayout('greetings_layout', DEFAULT_LAYOUT)
    const [font, setFont] = usePersistState<Font | undefined>('greetings_font', DEFAULT_FONT)
    const [messageColor, setMessageColor] = usePersistState<MessageColor | undefined>('greetings_messageColor', DEFAULT_COLOR)
    const [promotion, setPromotion] = usePersistState<number>('greetings_promotion', 1.0)
    const [promoCodeId, setPromoCodeId] = usePersistState<string | undefined>('greetings_promoCodeId', undefined)

    useEffect(() => {
        handleLoadCachedImages()
    }, [])

    const initialContext = {
        id, setId,
        images, setImages,
        handleCacheImage, handleLoadCachedImages,
        message, setMessage,
        location, setLocation,
        latitude, setLatitude,
        longitude, setLongitude,
        addresses, setAddresses,
        fileName, setFileName,
        frame, setFrame,
        layout, setLayout,
        font, setFont,
        messageColor, setMessageColor,
        promotion, setPromotion,
        promoCodeId, setPromoCodeId
    }

    return <GreetingsPostcardContext.Provider value={initialContext}>
        <InnerGreetingsPostcardApp />
    </GreetingsPostcardContext.Provider>
}

export default GreetingsAppPage