import PostcardTemplate from "./PostcardTemplate";
import QRCodeImage from "../header/QRCodeImage";
import {useMediaQuery, useTheme} from "@mui/material";
import {Dispatch, SetStateAction} from "react";
import {EditLocationProps} from "../header/location/location";
import EditLocationComponentSmall from "../header/location/EditLocationComponentSmall";
import EditLocationComponentLarge from "../header/location/EditLocationComponentLarge";
import {EditMessageProps} from "../body/message/message";
import EditMessageComponentLarge from "../body/message/EditMessageComponentLarge";
import EditMessageComponentSmall from "../body/message/EditMessageComponentSmall";

type MessageAndLocationProps = {
    message: string
    font?: string
    color?: string
    setMessage:  Dispatch<SetStateAction<string>> | ((newState: string) => string)

    location: string
    latitude: number
    longitude: number
    setLocation:  Dispatch<SetStateAction<string>> | ((newState: string) => string)
    setLatitude:  Dispatch<SetStateAction<number>> | ((newState: number) => number)
    setLongitude:  Dispatch<SetStateAction<number>> | ((newState: number) => number)
}

const MessageAndLocationPostcardBack = (props: MessageAndLocationProps) => {
    const {
        message, font, color, setMessage,
        location, latitude, longitude,
        setLocation, setLatitude, setLongitude
    } = props

    const theme = useTheme();
    const belowMedium = useMediaQuery(theme.breakpoints.down('md'))

    const locationComponentProps: EditLocationProps = {
        location, latitude, longitude,
        setLocation, setLatitude, setLongitude
    }

    const messageComponentProps: EditMessageProps = {
        message, font, color, setMessage
    }

    return <PostcardTemplate
        location={belowMedium ?
            <EditLocationComponentSmall {...locationComponentProps} /> :
            <EditLocationComponentLarge {...locationComponentProps} />
        }
        qrcode={<QRCodeImage isGiftQRCode={false} />}
        message={belowMedium ?
            <EditMessageComponentSmall {...messageComponentProps} /> :
            <EditMessageComponentLarge {...messageComponentProps} />
        }
        address={<></>}
    />
}

export default MessageAndLocationPostcardBack