import React, {Dispatch, SetStateAction, useContext, useEffect} from "react";
import {useAuthenticator} from "@aws-amplify/ui-react";
import nextDisabledContext from "./utils/nextDisabledContext";
import {Hub} from "aws-amplify/utils";
import CustomAuthenticator from "../../CustomAuthenticator";

function AuthenticatorWrapper(props: { authState: boolean, setAuthState: Dispatch<SetStateAction<boolean>>, children: JSX.Element }) {
    const { user } = useAuthenticator()

    const { updateIsNextDisabled } = useContext(nextDisabledContext)

    useEffect(() => {
        updateIsNextDisabled()
    }, [props.authState, user])

    useEffect(() => {
        if (user)  props.setAuthState(true)
    }, [user])

    useEffect(() => {
        const cancelSubscription = Hub.listen('auth', ({ payload }) => {
            switch (payload.event) {
                case 'signedIn':
                    console.log('user have been signedIn successfully.');
                    props.setAuthState(true)
                    break;
                case 'signedOut':
                    console.log('user have been signedOut successfully.');
                    break;
                case 'tokenRefresh':
                    console.log('auth tokens have been refreshed.');
                    break;
                case 'tokenRefresh_failure':
                    console.log('failure while refreshing auth tokens.');
                    break;
                case 'signInWithRedirect':
                    console.log('signInWithRedirect API has successfully been resolved.');
                    break;
                case 'signInWithRedirect_failure':
                    console.log('failure while trying to resolve signInWithRedirect API.');
                    break;
            }
        })
        return () => cancelSubscription()
    }, []);

    return props.authState ? props.children : <CustomAuthenticator />
}

export default AuthenticatorWrapper