import {getEmptyPostcard, getEmptyPostcardWithPrefilledData} from "../../common/postcardAppUtils";
import {useFieldSyncer, useSettingsFieldSyncer} from "./useFieldSyncer";
import {useState} from "react";
import {v4 as uuid} from 'uuid'
import {Postcard} from "../../../../utils/postcard";
import usePersistState from "../../../../utils/usePersistState";

const usePostcards = () => {
    const [postcards, setPostcards] = useState<Postcard[]>([getEmptyPostcard(uuid())])
    const [currentIndex, setCurrentIndex] = usePersistState<number>('currentIndex', 0)

    const syncerStateData = {
        _postcards: postcards,
        _currentIndex: currentIndex,
        _setPostcards: setPostcards
    }

    const addPostcard = async () => {
        const previousPostcard = postcards[currentIndex]
        const length = postcards.length
        await setPostcards([...postcards, await getEmptyPostcardWithPrefilledData(previousPostcard)])
        setCurrentIndex(length)
    }

    const removePostcard = async (id: string) => {
        const _postcards = postcards.filter(postcard => postcard.id !== id)
        setCurrentIndex(postcards.length - 2)
        await setPostcards(_postcards)
    }

    // Product settings
    const syncId = useFieldSyncer('id', syncerStateData)
    const syncImages = useFieldSyncer('images', { ...syncerStateData, _setPostcards: setPostcards })
    const syncMessage = useFieldSyncer('message', syncerStateData)
    const syncLocation = useFieldSyncer('location', syncerStateData)
    const syncLatitude = useFieldSyncer('latitude', syncerStateData)
    const syncLongitude = useFieldSyncer('longitude', syncerStateData)
    const syncFrame = useFieldSyncer('frame', syncerStateData)
    const syncLayout = useFieldSyncer('layout', syncerStateData)
    const syncFont = useFieldSyncer('font', syncerStateData)
    const syncMessageColor = useFieldSyncer('color', syncerStateData)

    // Service settings
    const syncAddress = useFieldSyncer('address', syncerStateData)
    const syncReplyBackSetting = useSettingsFieldSyncer('replyBackSetting', syncerStateData)
    const syncReplyAddressOfFirstPostcard = useSettingsFieldSyncer('replyAddress', { ...syncerStateData, _currentIndex: 0 })
    const syncChainSetting = useSettingsFieldSyncer('chainSetting', syncerStateData)
    const syncChain = useSettingsFieldSyncer('chain', syncerStateData)
    const syncInitialStateOfFirstPostcard = useSettingsFieldSyncer('initialState', { ...syncerStateData, _currentIndex: 0 })

    return {
        currentIndex,
        addPostcard,
        removePostcard,
        postcards,

        syncId,
        syncImages,
        syncMessage,
        syncLocation,
        syncLatitude,
        syncLongitude,
        syncFrame,
        syncLayout,
        syncFont,
        syncMessageColor,

        syncAddress,
        syncReplyBackSetting,
        syncReplyAddressOfFirstPostcard,
        syncChainSetting,
        syncChain,
        syncInitialStateOfFirstPostcard
    }
}

export default usePostcards
