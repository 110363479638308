import {
    createGreetingsOrder,
    createGreetingsPostcard,
    createOrderWithPostcardsAndOptionalPromoCode,
    createPostcard,
    fetchImageFromUri,
    IDObject,
    setPostcardReply
} from "../../../api/api";
import {v4 as uuid} from "uuid";
import {AuthUser} from '@aws-amplify/auth'
import {
    Address,
    Chain,
    CHAIN_SETTING,
    Font, Image,
    Postcard,
    PostcardSettings,
    REPLY_BACK_SETTING
} from "../../../utils/postcard";
import {PostcardAppInitialState, PostcardAppState} from '../postcard-app/utils/PostcardAppInitialState'
import {
    createGreetingsPostcardInDB,
    DBAddress,
    DBGreetingsAddress,
    DBGreetingsPostcard,
    DBImage,
    DBPostcard,
    DBPostcardSettings
} from "../../../api/apiUtils";
import {getBackendEnvironment, getLocation, invoke} from "../../../utils/utils";
import EXIF from "exif-js";
import {CurrentPostcardContextProps} from "../postcard-app/utils/currentPostcardContext";
import {getImagesNumberForLayout, LAYOUT} from "../../../utils/layout";
import GreetingsPostcardContext, {GreetingsPostcardContextProps} from "../greetings-postcard-app/utils/greetingsPostcardContext";
import React from "react";
import {CommonContextType} from "./utils/commonContextType";

export const DEFAULT_FONT = undefined
export const DEFAULT_COLOR = undefined
export const NO_FRAME = undefined
export const DEFAULT_LAYOUT = LAYOUT.SINGLE_IMAGE
export const NO_COORDINATE = -1000.00
export const DEFAULT_STATE: PostcardAppState = {
    initialState: PostcardAppInitialState.DEFAULT,
    body: {}
}
export const EMPTY_ADDRESS = (id: string): Address => ({
    id: id,
    fullName: '',
    streetAddress: '',
    city: '',
    zipCode: ''
})

const EMPTY_POSTCARD = {
    images: [],
    message: '',
    location: '',
    latitude: NO_COORDINATE,
    longitude: NO_COORDINATE,
    address: EMPTY_ADDRESS(uuid()),
    frame: NO_FRAME,
    layout: DEFAULT_LAYOUT,
    color: DEFAULT_COLOR,
    font: DEFAULT_FONT
}

export enum STEPS {
    IMAGE, MESSAGE_AND_LOCATION, RECIPIENT_AND_SETTINGS, PERSONAL_ADDRESS, CART
}

export type PostcardSubmissionBundle = {
    postcard: Postcard
    state: PostcardAppInitialState.DEFAULT
} | {
    postcard: Postcard
    state: PostcardAppInitialState.REPLY |
        PostcardAppInitialState.REPLY_FREE |
        PostcardAppInitialState.CHAIN_CONTINUATION_FREE |
        PostcardAppInitialState.CHAIN_CONTINUATION_TO_BE_PAID
    settingsToUpdate: string
    repliesToPostcardId: string
}

export type IDAndPrice = {
    id: string
    price: number
}

export type IDSPriceAndQuantity = {
    id: string
    price: number
    quantity: number
}

const getEmptyPostcard = (id: string): Postcard  => {
    const EMPTY_SETTINGS: PostcardSettings = {
        id: id,
        replyBackSetting: REPLY_BACK_SETTING.NONE,
        chainSetting: CHAIN_SETTING.NONE,
        replyAddress: null,
        postcardSettingsRepliesToId: null,
        postcardSettingsReplyId: undefined,
        chain: null,
        initialState: PostcardAppInitialState.DEFAULT
    }
    return {
        id: uuid() as string,
        settings: EMPTY_SETTINGS,
        ...EMPTY_POSTCARD
    }
}

const getEmptyChain = (): Chain => {
    return {
        id: uuid() as string,
        name: ''
    }
}

const cloneImage = async (image: Image): Promise<Image> => {
    return { id: uuid(), index: image.index, path: image.path }
}

const getEmptyPostcardWithPrefilledData = async (previousPostcard: Postcard): Promise<Postcard> => {
    let postcard = getEmptyPostcard(uuid())
    const imagePromises = []
    for (const image of previousPostcard.images)
        imagePromises.push(cloneImage(image))

    postcard.images = await Promise.all(imagePromises)
    postcard.frame = previousPostcard.frame
    postcard.layout = previousPostcard.layout
    postcard.location = previousPostcard.location
    postcard.latitude = previousPostcard.latitude
    postcard.longitude = previousPostcard.longitude
    return postcard
}

const processPostcardsCreation = async (postcardSubmissionBundles: PostcardSubmissionBundle[]): Promise<boolean> => {
    let isValid = true
    const postcardResponses = postcardSubmissionBundles.map(postcardSubmissionBundle => processSubmissionBundle(postcardSubmissionBundle))
    if (postcardSubmissionBundles[0].state !== PostcardAppInitialState.DEFAULT) {
        const settingsUpdateResult = await setPostcardAsReply(postcardSubmissionBundles[0])
        if (!settingsUpdateResult)
            console.warn('Unable to update settings')
            // TODO notify devs
    }
    await Promise.all(postcardResponses)
    for (const promise of postcardResponses) {
        const value = await promise
        if (!value)
            isValid = false
    }
    return isValid
}

const processSubmissionBundle = async (postcardSubmissionBundle: PostcardSubmissionBundle): Promise<false | IDObject> => {
    const state = postcardSubmissionBundle.state
    const postcard = postcardSubmissionBundle.postcard
    const settings = postcard.settings
    const addressId = uuid()
    const replyAddressId = uuid()
    const dBPostcard: DBPostcard = {
        id: postcard.id,
        message: postcard.message,
        postcardRecipientAddressId: addressId,
        location: postcard.location,
        latitude: postcard.latitude,
        longitude: postcard.longitude,
        postcardFrameId: postcard.frame?.id,
        layout: postcard.layout,
        postcardFontId: postcard.font?.id,
        postcardMessageColorId: postcard.color?.id
    }
    const dbAddress: DBAddress = { ...postcard.address, id: addressId }
    const dBImages: DBImage[] = postcard.images.map(image => {
        // Refresh ids to prevent errors coming from caching / generate path here for the same reason
        return { ...image, id: uuid(), postcardImagesId: postcard.id }
    })
    const dbSettingsAddress: DBAddress | undefined = settings.replyAddress ? { ...settings.replyAddress, id: replyAddressId } : undefined
    const dBSettings: DBPostcardSettings = {
        id: uuid(),
        replyBackSetting: settings.replyBackSetting,
        postcardSettingsReplyAddressId: settings.replyAddress ? replyAddressId: undefined,
        chainSetting: settings.chainSetting,
        postcardSettingsPostcardId: postcard.id,
        initialState: state
    }
    let result
    switch (state) {
        case PostcardAppInitialState.DEFAULT:
            const eventualChainId = uuid()
             result = await createPostcard(dBPostcard, dbAddress, dBImages, {
                 ...dBSettings,
                 postcardSettingsRepliesToId: settings.postcardSettingsRepliesToId ? settings.postcardSettingsRepliesToId : undefined,
                 chainPostcardsId: settings.chainSetting !== CHAIN_SETTING.NONE && settings.chain?.id ? eventualChainId : undefined,
                 postcardSettingsReplyAddressId: settings.replyBackSetting === REPLY_BACK_SETTING.REPLY_PAID ? replyAddressId : undefined
            },  settings.chainSetting !== CHAIN_SETTING.NONE ? { ...settings.chain, id: eventualChainId } : undefined,
                 settings.replyBackSetting === REPLY_BACK_SETTING.REPLY_PAID ? dbSettingsAddress : undefined)
            break
        case PostcardAppInitialState.CHAIN_CONTINUATION_FREE:
        case PostcardAppInitialState.CHAIN_CONTINUATION_TO_BE_PAID:
            result = await createPostcard(dBPostcard, dbAddress, dBImages, {
                ...dBSettings,
                postcardSettingsRepliesToId: postcardSubmissionBundle.repliesToPostcardId ? postcardSubmissionBundle.repliesToPostcardId : undefined,
                chainPostcardsId: settings.chain?.id,
                postcardSettingsReplyAddressId: undefined
            }, undefined, undefined)
            break
        default:
            result = await createPostcard(dBPostcard, dbAddress, dBImages, {
                ...dBSettings,
                postcardSettingsRepliesToId: postcardSubmissionBundle.repliesToPostcardId ? postcardSubmissionBundle.repliesToPostcardId : undefined,
                chainPostcardsId: undefined,
                postcardSettingsReplyAddressId: undefined
            }, undefined, undefined)
            break
    }
    return await result
}

const setPostcardAsReply = async (postcardSubmissionBundle: PostcardSubmissionBundle): Promise<boolean> => {
    if (postcardSubmissionBundle.state === PostcardAppInitialState.DEFAULT) return false
    const settingsToUpdate = postcardSubmissionBundle.settingsToUpdate
    const postcardId = postcardSubmissionBundle.postcard.id
    return await setPostcardReply(settingsToUpdate, postcardId)
}

const sendToCheckout = async (postcards: IDAndPrice[], _window: Window & any, promoCodeId: string | undefined): Promise<boolean> => {
    const environment = getBackendEnvironment()
    const response = await invoke(`createcheckoutsession-${environment}`, {
        items: postcards.map(postcard => ({
            price: postcard.price,
            quantity: 1
        })),
        location: getLocation(_window),
        postcardIds: postcards.map(postcard => postcard.id),
        promoCodeId: promoCodeId,
        isGreetings: false
    })
    if (!response.url) {
        return false
    } else {
        _window.location.href = response.url
        return true
    }
}

const processOrderSubmission = async (ids: string[], promoCodeId: string | null): Promise<boolean> => {
    let isValid = true

    if (ids.length === 0) return false

    const result = await createOrderWithPostcardsAndOptionalPromoCode(ids, promoCodeId)
    if (!result)
        isValid = false

    return isValid
}

const processGreetingsOrderSubmission = async (id: string): Promise<boolean> => {
    const result = await createGreetingsOrder(id)
    return !!result
}

const shouldUpdateUserAddress = (userAddress: Address, replyAddress: Address): boolean => {
    return !userAddress && replyAddress
}

const getState = (state: any): PostcardAppState => {
    if (state)
        return {
            initialState: state.initialState,
            body: state.body
        }
    return DEFAULT_STATE
}

const getNextStep = (step: STEPS, currentIndex: number, initialState: PostcardAppInitialState, replyBack: boolean): STEPS => {
    if (step === STEPS.CART) return step

    if (step === STEPS.MESSAGE_AND_LOCATION && currentIndex === 0) {
        switch (initialState) {
            case PostcardAppInitialState.REPLY_FREE:
                return STEPS.CART
            default:
                return STEPS.RECIPIENT_AND_SETTINGS
        }
    } else if (step === STEPS.RECIPIENT_AND_SETTINGS) {
        // NB: replyBack can be true only in DEFAULT state
        if (replyBack) return STEPS.PERSONAL_ADDRESS
        else return STEPS.CART
    } else return step + 1
}

const getGreetingsNextStep = (step: STEPS): STEPS => {
    if (step === STEPS.CART) return step
    else if (step === STEPS.RECIPIENT_AND_SETTINGS) return STEPS.CART
    else return step + 1
}

const getPreviousStep = (step: STEPS, currentIndex: number, initialState: PostcardAppInitialState, replyBack: boolean): STEPS => {
    if (step === STEPS.IMAGE) return step

    if (step === STEPS.CART) {
        switch (initialState) {
            case PostcardAppInitialState.REPLY_FREE:
                return STEPS.MESSAGE_AND_LOCATION
            case PostcardAppInitialState.DEFAULT:
                // NB: replyBack can be true only in DEFAULT state
                if (replyBack) return STEPS.PERSONAL_ADDRESS
                else return STEPS.RECIPIENT_AND_SETTINGS
            default:
                return STEPS.RECIPIENT_AND_SETTINGS
        }
    } else return step - 1
}

const getGreetingsPreviousStep = (step: STEPS): STEPS => {
    if (step === STEPS.IMAGE) return step
    else if (step === STEPS.CART) return STEPS.RECIPIENT_AND_SETTINGS
    else return step - 1
}

const getPrice = (state: PostcardAppInitialState) => {
    switch (state) {
        case PostcardAppInitialState.DEFAULT:
            return 3.59
        case PostcardAppInitialState.REPLY_FREE:
            // for completeness
            return 0.0
        case PostcardAppInitialState.REPLY:
            return 3.59
        case PostcardAppInitialState.CHAIN_CONTINUATION_FREE:
            return 0.0
        case PostcardAppInitialState.CHAIN_CONTINUATION_TO_BE_PAID:
            return 3.59
    }
}

const getGreetingsPrice = () => {
    return 0
}

const getReplyBackSetting = (currentIndex: number, initialState: PostcardAppInitialState, replyBack: boolean): REPLY_BACK_SETTING => {
    if (currentIndex === 0) {
        switch (initialState) {
            case PostcardAppInitialState.DEFAULT:
                return replyBack ? REPLY_BACK_SETTING.REPLY_PAID : REPLY_BACK_SETTING.NONE
            default:
                return REPLY_BACK_SETTING.NONE
        }
    } else {
        return replyBack ? REPLY_BACK_SETTING.REPLY_PAID : REPLY_BACK_SETTING.NONE
    }
}

const getChainSetting = (currentIndex: number, initialState: PostcardAppInitialState, chainStartOrContinuation: boolean) => {
    if (currentIndex === 0) {
        switch (initialState) {
            case PostcardAppInitialState.CHAIN_CONTINUATION_FREE:
            case PostcardAppInitialState.CHAIN_CONTINUATION_TO_BE_PAID:
                return chainStartOrContinuation ? CHAIN_SETTING.CHAIN_PAID : CHAIN_SETTING.CHAIN
            default:
                return chainStartOrContinuation ? CHAIN_SETTING.CHAIN_PAID : CHAIN_SETTING.NONE
        }
    } else {
        return chainStartOrContinuation ? CHAIN_SETTING.CHAIN_PAID : CHAIN_SETTING.NONE
    }
}

const preparePostcardsForSubmission = (postcard: Postcard, state: PostcardAppState): PostcardSubmissionBundle => {
    switch (state.initialState) {
        case PostcardAppInitialState.DEFAULT:
            return {
                postcard: postcard,
                state: state.initialState
            }
        case PostcardAppInitialState.REPLY:
        case PostcardAppInitialState.REPLY_FREE:
        case PostcardAppInitialState.CHAIN_CONTINUATION_FREE:
        case PostcardAppInitialState.CHAIN_CONTINUATION_TO_BE_PAID:
            return {
                postcard: postcard,
                state: state.initialState,
                settingsToUpdate: state.body.settingsToUpdate,
                repliesToPostcardId: state.body.repliesToPostcardId
            }
    }
}

function fromDegreesToDecimal(coords: Number[], direction: string): number {
    const dd = (coords[0] as number) + (coords[1] as number)/60 + (coords[2] as number)/(60*60);

    if (direction === "S" || direction === "W") {
        return dd * -1;
    } // Don't do anything for N or E
    return dd;
}

type ExitDataObject = {
    GPSLatitude: Number[]
    GPSLongitude: Number[]
    GPSLatitudeRef: string
    GPSLongitudeRef: string
}

const _getImageMetadata = async (imageUri: string): Promise<ExitDataObject | false> => {
    try {
        const imageBlob = await fetchImageFromUri(imageUri)
        return EXIF.readFromBinaryFile(await imageBlob.arrayBuffer())
    } catch (e) {
        console.warn("Error while fetching image metadata: ", e)
        return false
    }
}

const getImageGPSCoordinates = async (imageUri: string): Promise<false | { latitude: number, longitude: number }> => {
    const metadata = await _getImageMetadata(imageUri)
    if (!metadata) return false
    return {
        latitude: fromDegreesToDecimal(
            metadata.GPSLatitude,
            metadata.GPSLatitudeRef
        ),
        longitude: fromDegreesToDecimal(
            metadata.GPSLongitude,
            metadata.GPSLongitudeRef
        )
    }
}

const _getMissingInformationForCommonStep = (
    step: STEPS,
    context: CommonContextType
): false | string => {
    switch (step) {
        case STEPS.IMAGE:
            const imagesNumber = getImagesNumberForLayout(context.layout ? context.layout : LAYOUT.SINGLE_IMAGE)
            let _images = []
            if (imagesNumber === 1 && context.images.length === 0)
                return 'Please add an image for your postcard'
            else if (imagesNumber !== context.images.length)
                return 'Please add all images for your postcard'
            return false

        case STEPS.MESSAGE_AND_LOCATION:
            if (!context.message)
                return 'Please add a message to your postcard'
            else if (!context.location)
                return 'Please select the location of where the picture was taken'
            else if (context.latitude === NO_COORDINATE
                || context.longitude === NO_COORDINATE)
                return 'Please select a location from the available ones'
            return false

        case STEPS.CART:
            // No information to be set on cart step
            return false
    }
    return false
}

const getMissingInformationForStep = (
    step: STEPS,
    context: CurrentPostcardContextProps
): false | string => {
    switch (step) {
        case STEPS.IMAGE:
        case STEPS.MESSAGE_AND_LOCATION:
        case STEPS.CART:
            return _getMissingInformationForCommonStep(step, context)

        case STEPS.RECIPIENT_AND_SETTINGS:
            if (!context.address.streetAddress ||
                !context.address.fullName ||
                !context.address.city ||
                !context.address.zipCode)
                return 'Please fill all address information'
            else if (context.chainStartOrContinuation && !context.chainName)
                return 'Please add a name to your chain'
            return false

        case STEPS.PERSONAL_ADDRESS:
            if (!context.replyAddress.streetAddress ||
                !context.replyAddress.fullName ||
                !context.replyAddress.city ||
                !context.replyAddress.zipCode)
                return 'Please fill all address information'
            return false
    }
}

const getNextButtonDisabled = (
    step: STEPS,
    isLoadingSubmission: boolean,
    user: AuthUser | boolean,
    context: CurrentPostcardContextProps
): boolean => {
    if (isLoadingSubmission) return true
    switch (step) {
        case STEPS.IMAGE:
            const imagesNumber = getImagesNumberForLayout(context.layout ? context.layout : LAYOUT.SINGLE_IMAGE)
            let _images = []
            for (let i = 0; i < imagesNumber; i++) {
                _images = context.images.filter(image => image.index === i)
                if (_images.length !== 1) return true
            }
            return false

        case STEPS.PERSONAL_ADDRESS:
            if (!user) return true
            return !!getMissingInformationForStep(step, context)

        case STEPS.CART:
        case STEPS.MESSAGE_AND_LOCATION:
        case STEPS.RECIPIENT_AND_SETTINGS:
            return !!getMissingInformationForStep(step, context)
    }
}

const getGreetingsMissingInformationForStep = (
    step: STEPS,
    context: GreetingsPostcardContextProps
): false | string => {
    switch (step) {
        case STEPS.IMAGE:
        case STEPS.MESSAGE_AND_LOCATION:
        case STEPS.CART:
            return _getMissingInformationForCommonStep(step, context)
        case STEPS.RECIPIENT_AND_SETTINGS:
            if (context.addresses.length === 0)
                return 'Please insert at least one address'
            return false
    }
    return false
}

const getGreetingsNextButtonDisabled = (
    step: STEPS,
    isLoadingSubmission: boolean,
    user: AuthUser | boolean,
    context: GreetingsPostcardContextProps
): boolean => {
    if (isLoadingSubmission) return true

    switch (step) {
        case STEPS.IMAGE:
            const imagesNumber = getImagesNumberForLayout(context.layout ? context.layout : LAYOUT.SINGLE_IMAGE)
            let _images = []
            for (let i = 0; i < imagesNumber; i++) {
                _images = context.images.filter(image => image.index === i)
                if (_images.length !== 1) return true
            }
            return false

        case STEPS.MESSAGE_AND_LOCATION:
        case STEPS.RECIPIENT_AND_SETTINGS:
        case STEPS.CART:
            return !!getGreetingsMissingInformationForStep(step, context)
    }
    return false
}

const getCartRecapLabelFromSetting = (setting: PostcardSettings): { src: string, label: string} => {
    if (setting.replyBackSetting === REPLY_BACK_SETTING.NONE && setting.chainSetting === CHAIN_SETTING.NONE)
        return { src: 'icons/app/cartstep/single.svg', label: 'Single postcard' }
    else if (setting.replyBackSetting !== REPLY_BACK_SETTING.NONE)
        return { src: 'icons/app/cartstep/reply.svg', label: 'Reply postcard' }
    else
        return { src: 'icons/app/cartstep/chain.svg', label: 'Chain postcard' }
}

const getPriceFromSettingAndInitialState = (setting: PostcardSettings, initialState: PostcardAppInitialState): number => {
    if (initialState === PostcardAppInitialState.REPLY_FREE) return 0.00
    if (initialState === PostcardAppInitialState.CHAIN_CONTINUATION_FREE) {
        if (setting.chainSetting === CHAIN_SETTING.CHAIN_PAID) return 2.49
        else return 0.00
    }
    if (initialState === PostcardAppInitialState.CHAIN_CONTINUATION_TO_BE_PAID) {
        if (setting.chainSetting === CHAIN_SETTING.CHAIN_PAID) return 6.08
        else return 3.59
    }
    if (initialState === PostcardAppInitialState.DEFAULT) {
        if (setting.replyBackSetting === REPLY_BACK_SETTING.REPLY_PAID) return 6.08
        if (setting.chainSetting === CHAIN_SETTING.CHAIN_PAID) return 6.08
        else return 3.59
    }
    return 3.59
}

// TODO Make all of this dynamic from DB
const sortFonts = (fonts: Font[]): Font[] => {
    const pecitaId = '4c6d8391-1a19-4871-880a-548eaa29eb63'
    const namunPenId = '4d7f2c15-5d42-4073-9db4-e7b4c2076b92'
    const segoeId = '4db3d718-d5fd-489d-8d0b-0c5f1b22fa80'
    const dancingScriptId = 'd5c089fc-0152-4feb-9bcf-e4b2040e48f8'

    const font0 = fonts.find(font => font.id === dancingScriptId)
    const font1 = fonts.find(font => font.id === pecitaId)
    const font2 = fonts.find(font => font.id === segoeId)
    const font3 = fonts.find(font => font.id === namunPenId)

    const result = []
    if (font0) result.push(font0)
    if (font1) result.push(font1)
    if (font2) result.push(font2)
    if (font3) result.push(font3)
    return result
}

const sendToGreetingsCheckout = async (postcard: IDSPriceAndQuantity, _window: Window & any, promotion: number): Promise<boolean> => {
    const GREETINGS_5_PROMO_CODE_ID = 'f2e25821-bed5-44ae-acb4-f645037ff145'
    const GREETINGS_10_PROMO_CODE_ID = 'b97d823b-f617-4887-bb3d-7f93615e3811'
    const GREETINGS_15_PROMO_CODE_ID = '7e0bd54a-baae-4fc6-a6e1-e416794ceaf1'
    const GREETINGS_20_PROMO_CODE_ID = 'fb1d4dae-d48b-4b04-a3d3-8bc75f22e721'

    let promoCodeId
    switch (promotion) {
        case 0.95:
            promoCodeId = GREETINGS_5_PROMO_CODE_ID
            break
        case 0.90:
            promoCodeId = GREETINGS_10_PROMO_CODE_ID
            break
        case 0.85:
            promoCodeId = GREETINGS_15_PROMO_CODE_ID
            break
        case 0.80:
            promoCodeId = GREETINGS_20_PROMO_CODE_ID
            break
        default:
            promoCodeId = undefined
    }

    const environment = getBackendEnvironment()
    const response = await invoke(`createcheckoutsession-${environment}`, {
        items: [{
            price: postcard.price,
            quantity: postcard.quantity
        }],
        location: getLocation(_window),
        postcardIds: [postcard.id],
        promoCodeId: promoCodeId,
        isGreetings: true
    })
    if (!response.url) {
        return false
    } else {
        _window.location.href = response.url
        return true
    }
}

const processGreetingsSubmission = async (
    context: GreetingsPostcardContextProps
): Promise<false | IDObject> => {
    const postcardId = uuid()
    const postcard: DBGreetingsPostcard = {
        id: postcardId,
        message: context.message,
        location: context.location,
        latitude: context.latitude,
        longitude: context.longitude,
        layout: context.layout,
        greetingsPostcardFrameId: context.frame?.id,
        greetingsPostcardFontId: context.font?.id,
        greetingsPostcardMessageColorId: context.messageColor?.id
    }

    const addresses: DBGreetingsAddress[] = context.addresses.map(address => ({
        ...address,
        id: uuid(),
        greetingsPostcardRecipientAddressesId: postcardId
    }))

    const images: DBImage[] = context.images.map(image =>  ({
        ...image,
        id: uuid(),
        greetingsPostcardImagesId: postcardId
    }))

    return await createGreetingsPostcard(postcard, addresses, images)
}

const getGreetingsPromotion = (quantity: number): number => {
    if (quantity <= 50) {
        return 1.0
    } else {
        return 0.95
    }
}

export {
    getEmptyChain,
    getEmptyPostcard,
    getEmptyPostcardWithPrefilledData,
    processPostcardsCreation,
    sendToCheckout,
    processOrderSubmission,
    processGreetingsOrderSubmission,
    shouldUpdateUserAddress,
    getState,
    getNextStep,
    getGreetingsNextStep,
    getPreviousStep,
    getGreetingsPreviousStep,
    getPrice,
    getGreetingsPrice,
    getReplyBackSetting,
    getChainSetting,
    preparePostcardsForSubmission,
    getImageGPSCoordinates,
    getMissingInformationForStep,
    getNextButtonDisabled,
    getGreetingsMissingInformationForStep,
    getGreetingsNextButtonDisabled,
    getCartRecapLabelFromSetting,
    getPriceFromSettingAndInitialState,
    sortFonts,
    sendToGreetingsCheckout,
    processGreetingsSubmission,
    getGreetingsPromotion
}