import PageWrapper from "../components/PageWrapper";
import {Navigate} from "react-router-dom";
import {Authenticator, useAuthenticator} from "@aws-amplify/ui-react";
import {useLocationState} from "../utils/useLocationState";
import {useEffect, useState} from "react";
import {Hub} from "aws-amplify/utils";
import CustomAuthenticator from "../components/CustomAuthenticator";
import {Box} from "@mui/material";
// @ts-ignore
import { Helmet } from 'react-helmet'

type AuthStateParams = {
    redirectsTo?: string,
    paramsToRemember: undefined
} | {
    redirectsTo: string,
    paramsToRemember: string
}

const AuthenticationPage = () => {
    const state = useLocationState<AuthStateParams>()
    const { user } = useAuthenticator()
    const [authState, setAuthState] = useState<boolean>(!!user);

    useEffect(() => {
        const cancelSubscription = Hub.listen('auth', ({ payload }) => {
            switch (payload.event) {
                case 'signedIn':
                    console.log('user have been signedIn successfully.');
                    setAuthState(true)
                    break;
                case 'signedOut':
                    console.log('user have been signedOut successfully.');
                    break;
                case 'tokenRefresh':
                    console.log('auth tokens have been refreshed.');
                    break;
                case 'tokenRefresh_failure':
                    console.log('failure while refreshing auth tokens.');
                    break;
                case 'signInWithRedirect':
                    console.log('signInWithRedirect API has successfully been resolved.');
                    break;
                case 'signInWithRedirect_failure':
                    console.log('failure while trying to resolve signInWithRedirect API.');
                    break;
            }
        })
        return () => cancelSubscription()
    }, []);

    const redirectsTo = state?.redirectsTo
    let paramsToRemember: string | undefined = undefined
    if (redirectsTo)
        paramsToRemember = state?.paramsToRemember

    const getRedirectURL = (redirects: string): string => {
        console.log(redirects)
        console.log(paramsToRemember)
        if (paramsToRemember) {
            return redirects + '?' + paramsToRemember
        } else return redirects
    }

    return <PageWrapper>
        <Helmet>
            <meta name="title" content="Login - Memento Card | Accesso - Memento Card" />
            <meta name="description" content="Log in to your Memento Card account to
manage and personalize your postcard. Enter your credentials to access your
personal space. | Accedi al tuo account Memento Card per gestire e
personalizzare la tua cartolina. Inserisci le tue credenziali per entrare nel tuo
spazio personale." />
            <meta name="keywords" content="Login Memento Card, account access,
manage postcards, personalize postcards, Accesso Memento Card, login,
account Memento Card, gestione cartoline, personalizzazione cartoline" />
        </Helmet>
        <Box sx={{ pt: '40px', px: 2 }}>
            { authState ? <Navigate to={redirectsTo ? getRedirectURL(redirectsTo) : '/'} /> : <CustomAuthenticator /> }
        </Box>
    </PageWrapper>
}

export default AuthenticationPage