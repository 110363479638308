import {getNextStep, STEPS} from "../../common/postcardAppUtils";
import {Box, Typography, useMediaQuery, useTheme} from "@mui/material";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import {Dispatch, SetStateAction} from "react";

type CurrentStepBreadcrumbsProps = {
    currentStep: STEPS
    isGreetings?: boolean
    setStep: Dispatch<SetStateAction<STEPS>> | ((newState: STEPS) => STEPS)
    nextStep: STEPS
    isNextEnabled: boolean
}

const mapStepToLabel = (step: STEPS, isBelowMd: boolean, isGreetings?: boolean) => {
    const address = isGreetings ? 'Addresses' : 'Address'
    const insertTheAddress = isGreetings ? 'Insert the addresses' : 'Insert the address'

    switch (step) {
        case STEPS.IMAGE:
            return isBelowMd ? 'Image' : 'Upload an image'
        case STEPS.MESSAGE_AND_LOCATION:
            return isBelowMd ? 'Message' : 'Write a message'
        case STEPS.RECIPIENT_AND_SETTINGS:
        case STEPS.PERSONAL_ADDRESS:
            return isBelowMd ? address : insertTheAddress
        case STEPS.CART:
            return isBelowMd ? 'Shipment' : 'Ship a real postcard'
    }
}

const mapStepToImage = (step: STEPS, currentStep: STEPS) => {
    switch (step) {
        case STEPS.IMAGE:
            return 'image.svg'
        case STEPS.MESSAGE_AND_LOCATION:
            return 'message.svg'
        case STEPS.RECIPIENT_AND_SETTINGS:
        case STEPS.PERSONAL_ADDRESS:
            return 'home.svg'
        case STEPS.CART:
            return 'ship.svg'
    }
}

const CurrentStepBreadcrumbs = ({ currentStep, isGreetings, setStep, nextStep, isNextEnabled }: CurrentStepBreadcrumbsProps) => {
    const theme = useTheme()
    const belowMedium = useMediaQuery(theme.breakpoints.down('md'))
    const belowSmall = useMediaQuery(theme.breakpoints.down('sm'))

    const shouldMakeStepClickable = (step: STEPS): boolean => {
        if (step === nextStep && isNextEnabled) return true
        else return step < currentStep
    }

    return <Box sx={{
        display: 'flex',
        height: 40,
        py: 0,
        flexDirection: 'row',
        alignItems: 'center',
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        borderRadius: 32,
    }}>
        {[STEPS.IMAGE, STEPS.MESSAGE_AND_LOCATION, STEPS.RECIPIENT_AND_SETTINGS, STEPS.CART].map(step => <Box
            sx={{
                height: 40,
                px: 2,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                '&:hover': shouldMakeStepClickable(step) ? {
                    cursor: 'pointer'
                } : { },
                '&:hover #underline': shouldMakeStepClickable(step) ? {
                    backgroundColor: 'white !important'
                } : { },
                backgroundColor: step > currentStep ? '#ACADB2' : theme.palette.primary.main,
                borderTopLeftRadius: step === STEPS.IMAGE || step === nextStep ? 20 : 0,
                borderBottomLeftRadius: step === STEPS.IMAGE || step === nextStep ? 20 : 0,
                borderTopRightRadius: step === STEPS.CART ? 20 : 0,
                borderBottomRightRadius: step === STEPS.CART ? 20 : 0,
            }}
            key={step.toString()}
            onClick={() => {
                shouldMakeStepClickable(step) && setStep(step)
            }}
        >
            {!belowSmall && <img src={'icons/app/imagestep/' + mapStepToImage(step, currentStep)} alt={''}/>}
            <Box sx={{ ml: 1 }}>
                {/* @ts-ignore */}
                <Typography variant={'small'} sx={{
                    color: 'white',
                    pb: 0.5
                }}>{mapStepToLabel(step, belowMedium, isGreetings)}</Typography>
                <Box id={'underline'} sx={{ width: '100%', height: '2px', backgroundColor: currentStep === step ? 'white' : 'transparent' }} />
            </Box>
        </Box>)}
    </Box>
}

export default CurrentStepBreadcrumbs