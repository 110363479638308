import PostcardTemplate from "./PostcardTemplate";
import {Address as AddressType} from "../../../../utils/postcard";
import LocationBox from "../header/location/LocationBox";
import QRCodeImage from "../header/QRCodeImage";
import MessageBox from "../body/message/MessageBox";
import AddressBox from "../body/address/AddressBox";

type StaticPostcardProps = {
    message: string
    font?: string
    color?: string

    location: string
    latitude: number
    longitude: number

    address: AddressType

    isGiftQRCode: boolean
}

const StaticPostcardBack = (props: StaticPostcardProps) => {
    const {
        message, font, color,
        location, latitude, longitude,
        address, isGiftQRCode
    } = props

    return <PostcardTemplate
        location={<LocationBox
            location={location}
            latitude={latitude}
            longitude={longitude}
        />}
        qrcode={<QRCodeImage isGiftQRCode={isGiftQRCode} />}
        message={<MessageBox
            font={font}
            color={color}
            message={message}
        />}
        address={<AddressBox address={address} />}
    />
}

export default StaticPostcardBack