import {CHAIN_SETTING, Postcard, REPLY_BACK_SETTING} from "../../../utils/postcard";
import {STEPS} from "../../app/common/postcardAppUtils";
import CartPostcardRecapTemplate from "./CartPostcardRecapTemplate";

type CartPostcardRecapProps = {
    postcard: Postcard
    onRemovePostcard: (id: string) => void
    onlyOnePostcard: boolean
    price: number,
    setStep: (newState: STEPS) => STEPS
}

const CartPostcardRecap = ({ postcard, onRemovePostcard, onlyOnePostcard, price, setStep }: CartPostcardRecapProps) => {
    return <CartPostcardRecapTemplate
        { ...postcard }
        price={price}
        isDelete={true}
        onRemovePostcard={() => {
            if (onlyOnePostcard) {
                setStep(STEPS.IMAGE)
            } else {
                onRemovePostcard(postcard.id)
            }
        }}
        isReplyBack={postcard.settings.replyBackSetting !== REPLY_BACK_SETTING.NONE}
        isChain={postcard.settings.chainSetting !== CHAIN_SETTING.NONE}
    />
}

export default CartPostcardRecap
