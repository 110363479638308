import {Dispatch, SetStateAction} from "react";
import {Font, Frame, Image, MessageColor} from "../../../../utils/postcard";
import {LAYOUT} from "../../../../utils/layout";
import {DEFAULT_COLOR, DEFAULT_FONT, DEFAULT_LAYOUT, NO_COORDINATE, NO_FRAME} from "../postcardAppUtils";

type CommonContextType = {
    id: string,
    setId: Dispatch<SetStateAction<string>> | ((newState: string) => string) | ((newState: string) => Promise<string>)
    images: Image[],
    setImages: Dispatch<SetStateAction<Image[]>> | ((newState: Image[]) => Image[]) | ((newState: Image[]) => Promise<Image[]>)
    handleCacheImage: (index: number, path: File) => void
    handleLoadCachedImages: () => void
    frame: Frame | undefined,
    setFrame: Dispatch<SetStateAction<Frame | undefined>> | ((newState: Frame | undefined) => Frame | undefined)
    layout: LAYOUT,
    setLayout: Dispatch<SetStateAction<LAYOUT>> | ((newState: LAYOUT) => LAYOUT)
    message: string,
    setMessage: Dispatch<SetStateAction<string>> | ((newState: string) => string)
    location: string,
    setLocation: Dispatch<SetStateAction<string>> | ((newState: string) => string)
    font: Font | undefined,
    setFont: Dispatch<SetStateAction<Font | undefined>> | ((newState: Font | undefined) => Font | undefined)
    messageColor: MessageColor | undefined
    setMessageColor: Dispatch<SetStateAction<MessageColor | undefined>> | ((newState: MessageColor | undefined) => MessageColor | undefined)
    latitude: number,
    setLatitude: Dispatch<SetStateAction<number>> | ((newState: number) => number)
    longitude: number,
    setLongitude: Dispatch<SetStateAction<number>> | ((newState: number) => number)
    promotion: number,
    setPromotion: Dispatch<SetStateAction<number>> | ((newState: number) => number)
    promoCodeId: string | undefined,
    setPromoCodeId: Dispatch<SetStateAction<string | undefined>> | ((newState: string | undefined) => string | undefined)
}

const EMPTY_FUNC = () => {}

const initialCommonContext: CommonContextType = {
    id: '', setId: EMPTY_FUNC,
    images: [], setImages: EMPTY_FUNC,
    handleCacheImage: EMPTY_FUNC, handleLoadCachedImages: EMPTY_FUNC,
    frame: NO_FRAME, setFrame: EMPTY_FUNC,
    layout: DEFAULT_LAYOUT, setLayout: EMPTY_FUNC,
    message: '', setMessage: EMPTY_FUNC,
    location: '', setLocation: EMPTY_FUNC,
    font: DEFAULT_FONT, setFont: EMPTY_FUNC,
    messageColor: DEFAULT_COLOR, setMessageColor: EMPTY_FUNC,
    latitude: NO_COORDINATE, setLatitude: EMPTY_FUNC,
    longitude: NO_COORDINATE, setLongitude: EMPTY_FUNC,
    promotion: 1.0, setPromotion: EMPTY_FUNC,
    promoCodeId: undefined, setPromoCodeId: EMPTY_FUNC
}

export type { CommonContextType }
export { initialCommonContext }