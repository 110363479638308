import {AuthenticatorProps} from "@aws-amplify/ui-react/dist/types/components/Authenticator/Authenticator";
import {Authenticator, View, Text} from "@aws-amplify/ui-react";


const CustomAuthenticator = (props: AuthenticatorProps) => {
    const currentYear = (new Date()).getFullYear()

    const FooterComponent = <View textAlign="center" style={{ paddingBottom: 8, backgroundColor: 'white', paddingLeft: 16, paddingRight: 16 }}>
            <Text fontSize={12}>
                By registering you agree to the <a href={'/terms-and-conditions'}>Terms and Condition</a> and <a href={'/privacy-policy'}>Privacy Policy</a> of Memento S.r.l.
                &copy; {currentYear} Memento S.r.l. All Rights Reserved<br />
            </Text>
        </View>

    return <Authenticator { ...props } components={{ SignUp: { Footer() { return FooterComponent } } }} />
}

export default CustomAuthenticator