import {useCallback, useEffect, useState} from "react";
import {LAYOUT} from "./layout";
import {Image} from "./postcard";

export class CacheManager {
    static itemKeys: string[] = []

    static addKey(key: string): void {
        if (!this.itemKeys.includes(key)) CacheManager.itemKeys.push(key)
    }

    static removeKey(key: string): void {
        localStorage.removeItem(key)
        if (CacheManager.itemKeys.includes(key)) {
            const index = CacheManager.itemKeys.indexOf(key)
            CacheManager.itemKeys.splice(index, 1)
        }
    }

    static async clear(): Promise<void> {
        // TODO troubleshoot static deletion
        CacheManager.removeKey('_images_metadata')
        CacheManager.removeKey('id')
        CacheManager.removeKey('message')
        CacheManager.removeKey('location')
        CacheManager.removeKey('latitude')
        CacheManager.removeKey('longitude')

        CacheManager.removeKey('address')
        CacheManager.removeKey('replyBack')
        CacheManager.removeKey('chainStartOrContinuation')
        CacheManager.removeKey('replyAddress')
        CacheManager.removeKey('chainName')

        CacheManager.removeKey('frame')
        CacheManager.removeKey('font')
        CacheManager.removeKey('messageColor')
        CacheManager.removeKey('promotion')
        CacheManager.removeKey('promoCodeId')

        CacheManager.removeKey('step')
        CacheManager.removeKey('_postcard_metadata')
        CacheManager.removeKey('currentIndex')
        CacheManager.removeKey('selectedLayout')
        CacheManager.removeKey('colorId')

        CacheManager.removeKey('fontId')
        CacheManager.removeKey('layout')
    }

    static async clearGreetings(): Promise<void> {
        CacheManager.removeKey('_greetings_images_metadata')
        CacheManager.removeKey('greetings_id')
        CacheManager.removeKey('greetings_message')
        CacheManager.removeKey('greetings_location')
        CacheManager.removeKey('greetings_latitude')
        CacheManager.removeKey('greetings_longitude')

        CacheManager.removeKey('greetings_addresses')
        CacheManager.removeKey('greetings_file_name')

        CacheManager.removeKey('greetings_frame')
        CacheManager.removeKey('greetings_font')
        CacheManager.removeKey('greetings_messageColor')
        CacheManager.removeKey('greetings_promotion')
        CacheManager.removeKey('greetings_promoCodeId')

        CacheManager.removeKey('greetings_step')
        CacheManager.removeKey('greetings_selectedLayout')
        CacheManager.removeKey('greetings_colorId')

        CacheManager.removeKey('greetings_fontId')
        CacheManager.removeKey('greetings_layout')
    }
}

function persistItem<T extends Object | undefined>(key: string, value: T): T {
    localStorage.setItem(key, value === undefined ? 'undefined' : JSON.stringify(value))
    return value
}

function usePersistState<T extends Object | undefined>(key: string, initialValue: T): [T, (newState: T) => T] {
    const [state, setState] = useState<T>(
        // @ts-ignore
        () => {
            CacheManager.addKey(key)
            return localStorage.getItem(key) === null ?
                persistItem<T>(key, initialValue) :
                (localStorage.getItem(key) === 'undefined' ? undefined : (JSON.parse(localStorage.getItem(key) as string) as T))
        }
    )

    const setStateAndPersist = useCallback(
        (newState: T): T => {
            setState(newState)
            return persistItem<T>(key, newState)
        },
        [key, setState])

    return [state, setStateAndPersist]
}
export function usePersistStateLayout(key: string, initialValue: LAYOUT): [LAYOUT, (newState: LAYOUT) => LAYOUT] {
    const [state, setState] = useState<LAYOUT>(
        // @ts-ignore
        () => localStorage.getItem(key) === null ?
            persistItem<LAYOUT>(key, initialValue) :
            (localStorage.getItem(key) === 'undefined' ? undefined : LAYOUT[JSON.parse(localStorage.getItem(key) as string) as keyof typeof LAYOUT])
    )

    const setStateAndPersist = useCallback(
        (newState: LAYOUT): LAYOUT => {
            setState(newState)
            return persistItem<LAYOUT>(key, newState)
        },
        [key, setState])

    return [state, setStateAndPersist]
}

export function usePersistImages({storeName, cacheName}: {storeName: string, cacheName: string}): [Image[], (newState: Image[]) => Image[], (index: number, path: File) => void, () => void]  {
    const [images, setImages] = usePersistState<Image[]>(storeName, [])

    const setImagePath = (path: string, index: number) => {
        const _images = []
        for (let i = 0; i < images.length; i++)
            _images.push(images[i])
        _images[index].path = path
        setImages(_images)
    }

    const handleCacheImage = (index: number, value: File) => {
        const reader = new FileReader();
        const path = URL.createObjectURL(value)
        const image = images[index]
        const httpPath = '/' + image.id
        reader.onload = () => {
            const result = reader.result
            if (result) {
                fetch(result.toString())
                    .then(response => {
                        if (response.ok)
                            return response.blob();
                        throw new Error("Response was not ok")
                    })
                    .then(blob => {
                        saveImageToCache(httpPath, blob);
                        setImagePath(path, index);
                    })
                    .catch(error => {
                        console.error('Error fetching or caching image:', error);
                    });
            }
        };
        reader.readAsDataURL(value);
    };

    const saveImageToCache = async (url: string, blob: Blob) => {
        if ('caches' in window) {
            caches.open(cacheName).then(cache => {
                const headers = new Headers()
                headers.append('Content-Type', 'image/jpeg')
                cache.put(url, new Response(blob, { headers }))
            });
        }
    };

    const getImageFromCache = async (url: string): Promise<Response | undefined | null> => {
        if ('caches' in window) {
            const response = await caches.match(url)
            return response || null
        }
        return Promise.resolve(null);
    };

    const handleLoadCachedImages = async () => {
        const pathsToUpdate: string[] = []
        for (let index = 0; index < images.length; index++) {
            const image = images[index]
            const httpPath = '/' + image.id
            const response = await getImageFromCache(httpPath)
            if (response) {
                const blob = await response.blob()
                const newPath = URL.createObjectURL(blob)
                pathsToUpdate.push(newPath)
            } else {
                console.log('Image not found in cache.');
                pathsToUpdate.push(images[index].path)
            }
        }

        // Update all paths at once
        const _images = []
        for (let index = 0; index < images.length; index++) {
            _images.push({ ...images[index], path: pathsToUpdate[index] })
        }
        setImages(_images)
    }

    return [images, setImages, handleCacheImage, handleLoadCachedImages]
}

export default usePersistState