import PageWrapper from "../../PageWrapper";
import usePersistState from "../../../utils/usePersistState";
import {useContext, useEffect, useState} from "react";
import GreetingsPostcardContext from "./utils/greetingsPostcardContext";
import {useAuthenticator} from "@aws-amplify/ui-react";
import {
    getGreetingsMissingInformationForStep,
    getGreetingsNextButtonDisabled,
    getGreetingsNextStep,
    getGreetingsPreviousStep,
    getPrice,
    processGreetingsSubmission,
    sendToGreetingsCheckout,
    STEPS
} from "../common/postcardAppUtils";
import ImageStep from "../common/imagesselection/ImageStep";
import MessageAndLocationStep from "../common/MessageAndLocationStep";
import IsGreetingsContext from "../common/imagesselection/isGreetingsContext";
import RecipientStep from "./RecipientStep";
import CartStep from "./cartstep/CartStep"
import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography} from "@mui/material";
import CurrentStepBreadcrumbs from "../postcard-app/CurrentStepBreadcrumbs/CurrentStepBreadcrumbs";
import NextDisabledContext from "../postcard-app/utils/nextDisabledContext";
import {PostcardAppInitialState} from "../postcard-app/utils/PostcardAppInitialState";
import {useNavigate} from "react-router-dom";

const InnerGreetingsPostcardApp = () => {
    const initialStep = STEPS.IMAGE
    const navigate = useNavigate()

    const [step, setStep] = usePersistState<STEPS>('greetings_step', initialStep)
    const [loadingSubmission, setLoadingSubmission] = useState<boolean>(false)
    const [errorMessage, setErrorMessage] = useState<string>('')
    const [isNextDisabled, setIsNextDisabled] = useState<boolean>(true)
    const [dialogOpen, setDialogOpen] = useState<boolean>(false)

    const [authState, setAuthState] = useState<boolean>(false)

    const context = useContext(GreetingsPostcardContext)

    const {user} = useAuthenticator()

    const onSubmitOrder = async () => {
        setLoadingSubmission(true)
        const isValid = await processGreetingsSubmission(context)
        if (isValid) {
            const response = await sendToGreetingsCheckout({
                id: isValid.id,
                price: getPrice(PostcardAppInitialState.DEFAULT),
                quantity: context.addresses.length
            }, window, context.promotion)
            if (!response) {
                setErrorMessage('Failed to process submission')
                setLoadingSubmission(false)
                // TODO notify devs
            }
        } else {
            setErrorMessage('Failed to submit postcard')
            setLoadingSubmission(false)
        }
    }

    const onSubmitBankTransfer = async () => {
        setLoadingSubmission(true)
        const isValid = await processGreetingsSubmission(context)
        if (isValid) {
            // Redirect to success page
            navigate('/greetings-bk-success', {
                state: {
                    id: isValid.id,
                    price: getPrice(PostcardAppInitialState.DEFAULT) * context.promotion,
                    quantity: context.addresses.length
                }
            })
        } else {
            setErrorMessage('Failed to submit postcard')
            setLoadingSubmission(false)
        }
    }

    const initialNextDisabledContext = {
        isNextDisabled: isNextDisabled,
        updateIsNextDisabled: () => setIsNextDisabled(getGreetingsNextButtonDisabled(step, loadingSubmission, authState, context))
    }

    useEffect(() => {
        initialNextDisabledContext.updateIsNextDisabled()
        // eslint-disable-next-line
    }, [step, loadingSubmission, user, authState, context])

    const stepsComponents = [
        <ImageStep/>,
        <MessageAndLocationStep/>,
        <RecipientStep/>,
        <></>,
        <CartStep
            authState={authState}
            setAuthState={setAuthState}
            onSubmitOrder={onSubmitOrder}
            onSubmitBankTransfer={onSubmitBankTransfer}
        />
    ]

    const next = () => setStep(getGreetingsNextStep(step))
    const previous = () => setStep(getGreetingsPreviousStep((step)))

    return <PageWrapper>
        <IsGreetingsContext.Provider value={{ isGreetings: true }}>
            <Box sx={{ display: 'flex', flexGrow: 0, flexShrink: 0, width: '100%', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', mt: 4, mb: 2 }}>
                <CurrentStepBreadcrumbs
                    currentStep={step}
                    isGreetings={true}
                    setStep={setStep}
                    nextStep={getGreetingsNextStep(step)}
                    isNextEnabled={!isNextDisabled}
                />
            </Box>

            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', p: 2 }}>
                <NextDisabledContext.Provider value={initialNextDisabledContext}>
                    {stepsComponents[step]}
                </NextDisabledContext.Provider>
            </Box>

            <Box sx={{ flexGrow: 0, flexShrink: 0 }}>
                <Typography>{loadingSubmission && 'Loading submission...'}</Typography>
                <Typography>{errorMessage}</Typography>
            </Box>

            <Box sx={{ flexDirection: 'row', display: 'flex', width: '100%', justifyContent: 'center', gap: 4, mb: 8 }}>
                <Box sx={{ display: step === STEPS.IMAGE ? 'none' : 'block' }}>
                    <Button disabled={loadingSubmission} onClick={previous} variant={'contained'} color={'secondary'} className={'no-focus'}>
                        Back
                    </Button>
                </Box>
                <Box sx={{ display: step === STEPS.CART ? 'none' : 'block' }}>
                    <Button onClick={() => {
                        if (isNextDisabled) {
                            // Open error popup
                            setDialogOpen(true)
                        } else {
                            next()
                        }
                    }} variant={'contained'} className={'no-focus'} id={STEPS[step]}>
                        Next
                    </Button>
                </Box>
            </Box>
        </IsGreetingsContext.Provider>
        <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)} sx={{ '& .MuiPaper-root': { padding: 2, borderRadius: 4 } }}>
            <DialogTitle>
                Something is missing
            </DialogTitle>
            <DialogContent>
                {getGreetingsMissingInformationForStep(step, context)}
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setDialogOpen(false)} variant={'contained'}>
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    </PageWrapper>
}

export default InnerGreetingsPostcardApp