import {getAutocompleteLocations} from "../../../../../api/api";
import {Autocomplete, TextField} from "@mui/material";
import {Dispatch, SetStateAction, useState} from "react";
import {SuggestedLocation} from "../../../../../api/apiUtils";
import {NO_COORDINATE} from "../../../../app/common/postcardAppUtils";

type LocationAutocompleteProps = {
    location: string
    setLocation:  Dispatch<SetStateAction<string>> | ((newState: string) => string)
    setLatitude:  Dispatch<SetStateAction<number>> | ((newState: number) => number)
    setLongitude:  Dispatch<SetStateAction<number>> | ((newState: number) => number)
    setEditLocation: Dispatch<SetStateAction<boolean>> | ((newState: boolean) => boolean)
    isSmall: boolean
}

const LocationAutocomplete = ({ location, setLocation, setLatitude, setLongitude, setEditLocation, isSmall }: LocationAutocompleteProps) => {
    const [suggestedLocations, setSuggestedLocations] = useState<SuggestedLocation[]>([])
    const [apiCallTimeout, setApiCallTimeout] = useState<NodeJS.Timeout | null>(null)

    return <Autocomplete
        sx={{ ml: '2.82%', width: isSmall ? 250 : 220, position: 'absolute', zIndex: 2, '& .MuiInputBase-input': { p: '0px !important', pl: '4px !important' } }}
        onChange={(ignored, newValue) => {
            if (newValue && newValue.displayName) {
                // @ts-ignore
                setLocation(newValue.displayName)
                // @ts-ignore
                setLatitude(newValue.latitude)
                // @ts-ignore
                setLongitude(newValue.longitude)
            }
            setEditLocation(false)
        }}
        inputValue={location}
        onInputChange={(event, newInputValue) => {
            if (event && event.type === 'change') {
                // TODO debug too many request error
                const _location = newInputValue
                // @ts-ignore
                setLocation(_location)
                // @ts-ignore
                setLatitude(NO_COORDINATE)
                // @ts-ignore
                setLongitude(NO_COORDINATE)
                if (apiCallTimeout !== null)
                    clearTimeout(apiCallTimeout)
                setApiCallTimeout(setTimeout(() => {
                    getAutocompleteLocations(_location)
                        .then(result => {
                            if (result) {
                                setSuggestedLocations(result)
                            } else {
                                // TODO notify devs
                            }
                        })
                }, 1000))
            }
        }}
        renderInput={(params) => <TextField {...params} label="Where was this picture taken?" />}
        options={suggestedLocations}
        getOptionLabel={option => option.displayName}
        isOptionEqualToValue={(option, value) => option.latitude === value.latitude && option.longitude === value.longitude}
    />
}

export default LocationAutocomplete