import {Box, Card, CircularProgress, Typography, useMediaQuery, useTheme} from "@mui/material";
import {Dispatch, SetStateAction} from "react";

type MessageSettingsProps = {
    name: string
    items: { id: string, component: JSX.Element }[]
    currentItem: string,
    setCurrentItem: Dispatch<SetStateAction<string>> | ((newState: string) => string)
    isLoading: boolean
    errorMessage: string
}

const MessageSettings = ({ name, items, currentItem, setCurrentItem, isLoading, errorMessage }: MessageSettingsProps) => {
    const theme = useTheme()
    const belowMedium = useMediaQuery(theme.breakpoints.down('md'))

    return <Box sx={{ mb: 4, width: belowMedium ? '50%' : '100%' }}>
        <Box>
            <Typography variant={'body1'}>{name}</Typography>
        </Box>
        <Card sx={{ borderRadius: 3, mt: 2, p: 2, display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            { isLoading ? <CircularProgress sx={{ height: '34px !important' }} /> : (errorMessage ? <Box sx={{ height: '28px' }}>
                <Typography>{errorMessage}</Typography>
            </Box> : items.map(item => <Box onClick={() => setCurrentItem(item.id)} sx={{ cursor: 'pointer' }} key={item.id}>
                <>
                    {item.component}
                    <Box sx={{ height: '2px', width: '100%', backgroundColor: item.id === currentItem ? theme.palette.primary.main : 'transparent', mt: 1 }} />
                </>
            </Box>))}
        </Card>
    </Box>
}

export default MessageSettings