import {useContext, useState} from "react";
import {EditLocationProps} from "./location";
import {NO_COORDINATE} from "../../../../app/common/postcardAppUtils";
import EditLocationBox from "./EditLocationBox";
import {PostcardWidthContext} from "../../templates/PostcardTemplate";
import LocationBox from "./LocationBox";
import LocationAutocomplete from "./LocationAutocomplete";

const EditLocationComponentSmall = ({
    location, setLocation,
    latitude, setLatitude,
    longitude, setLongitude
}: EditLocationProps) => {
    const [editLocation, setEditLocation] = useState<boolean>(false)
    const onClickEditLocationBox = () => setEditLocation(true)

    const postcardWidth = useContext(PostcardWidthContext).width

    return !editLocation ? (
        (!location || longitude === NO_COORDINATE || longitude === NO_COORDINATE) ?
            <EditLocationBox
                onClick={onClickEditLocationBox}
                isSmall={true}
                postcardWidth={postcardWidth}
            /> : <LocationBox
                location={location}
                latitude={latitude}
                longitude={longitude}
                isClickable={true}
                onClick={onClickEditLocationBox}
                postcardWidth={postcardWidth}
            />
    ) : <LocationAutocomplete
        location={location}
        setLocation={setLocation} setLatitude={setLatitude} setLongitude={setLongitude}
        setEditLocation={setEditLocation}
        isSmall={true}
    />
}

export default EditLocationComponentSmall