import {Amplify} from 'aws-amplify';
import {Authenticator, ThemeProvider as AmplifyThemeProvider} from "@aws-amplify/ui-react";
import '@aws-amplify/ui-react/styles.css';

import {BrowserRouter, Route, Routes} from "react-router-dom";
import LandingPage from "./pages/LandingPage";
import NoPage from "./pages/NoPage";
import awsExports from './aws-exports-copy';
import PostcardAppPage from "./pages/PostcardAppPage";
import ProfilePage from "./pages/ProfilePage";
import AuthenticationPage from "./pages/AuthenticationPage";
import PasswordChangePage from "./pages/PasswordChangePage";
import PaymentSuccess from "./pages/PaymentSuccess";
import PaymentCancel from "./pages/PaymentCancel";
import PostcardScan from "./pages/PostcardScan";
import {CssBaseline, ThemeProvider as MuiThemeProvider} from "@mui/material";
import muiTheme from './utils/themes/muiTheme';
import amplifyTheme from "./utils/themes/amplifyTheme";
import ComingSoon from "./pages/ComingSoon";
import PDFs from "./pages/PDFs";
import Ambassador from "./pages/Ambassador";
import FAQ from "./pages/FAQ";
import AmbassadorContact from "./pages/AmbassadorContact";
import Contact from "./pages/Contact";
import TermsAndConditions from "./pages/compliance/TermsAndConditions";
import PrivacyPolicy from "./pages/compliance/PrivacyPolicy";
import CookiePolicy from "./pages/compliance/CookiePolicy";
import Greetings from "./pages/Greetings";
import GreetingsAppPage from "./pages/GreetingsAppPage";
import GreetingsPaymentSuccess from "./pages/GreetingsPaymentSuccess";
import GreetingsPaymentCancel from "./pages/GreetingsPaymentCancel";
import GreetingsBankTransferSuccess from "./pages/GreetingsBankTransferSuccess";
import GreetingsPostcardScan from "./pages/GreetingsPostcardScan";
// import LogRocket from 'logrocket';

// LogRocket.init('mbdl1m/memento');
Amplify.configure(awsExports);

// TODO test promo code feature
export default function App() {
    return (
        <AmplifyThemeProvider theme={amplifyTheme}>
            <MuiThemeProvider theme={muiTheme}>
                <CssBaseline />
                <Authenticator.Provider>
                    <BrowserRouter>
                        <Routes>
                            <Route index element={<LandingPage />} />
                            <Route path={'/app'} element={<PostcardAppPage />} />
                            <Route path={'/profile'} element={<ProfilePage />} />
                            <Route path={'/auth'} element={<AuthenticationPage />} />
                            <Route path={'/password-change'} element={<PasswordChangePage />} />
                            <Route path={'/payment-success'} element={<PaymentSuccess />} />
                            <Route path={'/payment-cancel'} element={<PaymentCancel />}/>
                            <Route path={'/greetings-payment-success'} element={<GreetingsPaymentSuccess />} />
                            <Route path={'/greetings-bk-success'} element={<GreetingsBankTransferSuccess />} />
                            <Route path={'/greetings-payment-cancel'} element={<GreetingsPaymentCancel />}/>
                            <Route path={'/postcard-scan'} element={<PostcardScan />} />
                            <Route path={'/greetings-postcard-scan'} element={<GreetingsPostcardScan />} />
                            <Route path={'/coming-soon'} element={<ComingSoon />}/>
                            <Route path={'/ambassador'} element={<Ambassador />} />
                            <Route path={'/ambassador-contact'} element={<AmbassadorContact />} />
                            <Route path={'/faq'} element={<FAQ />} />
                            <Route path={'/greetings'} element={<Greetings />} />
                            <Route path={'/greetings-app'} element={<GreetingsAppPage />} />
                            <Route path={'/contact'} element={<Contact />} />
                            <Route path={'/terms-and-conditions'} element={<TermsAndConditions />} />
                            <Route path={'/privacy-policy'} element={<PrivacyPolicy />} />
                            <Route path={'/cookie-policy'} element={<CookiePolicy />} />
                            <Route path={'/mem-staff-pdfs-temp'} element={<PDFs />}/>
                            <Route path={'*'} element={<NoPage />} />
                        </Routes>
                    </BrowserRouter>
                </Authenticator.Provider>
            </MuiThemeProvider>
        </AmplifyThemeProvider>
    )
}
