import usePersistState, {
    usePersistImages,
    usePersistStateLayout
} from "../utils/usePersistState";
import {v4 as uuid} from "uuid";
import {useEffect} from "react";
import {
    DEFAULT_COLOR,
    DEFAULT_FONT,
    DEFAULT_LAYOUT,
    EMPTY_ADDRESS,
    NO_COORDINATE,
    NO_FRAME
} from "../components/app/common/postcardAppUtils";
import {Address, Font, Frame, MessageColor} from "../utils/postcard";
import CurrentPostcardContext from "../components/app/postcard-app/utils/currentPostcardContext";
import {InnerPostcardAppPage} from "../components/app/postcard-app/InnerPostcardAppPage";

const CACHE_DATA = {
    storeName: '_images_metadata',
    cacheName: 'user-images-cache'
}

const PostcardAppPage = () => {
    const [id, setId] = usePersistState<string>('id', '')
    const [images, setImages, handleCacheImage, handleLoadCachedImages] = usePersistImages(CACHE_DATA)
    const [message, setMessage] = usePersistState<string>('message', '')
    const [location, setLocation] = usePersistState<string>('location', '')
    const [latitude, setLatitude] = usePersistState<number>('latitude', NO_COORDINATE)
    const [longitude, setLongitude] = usePersistState<number>('longitude', NO_COORDINATE)
    const [address, setAddress] = usePersistState<Address>('address', EMPTY_ADDRESS(uuid()))
    const [replyBack, setReplyBack] = usePersistState<boolean>('replyBack', false)
    const [chainStartOrContinuation, setChainStartOrContinuation] = usePersistState<boolean>('chainStartOrContinuation', false)
    const [replyAddress, setReplyAddress] = usePersistState<Address>('replyAddress', EMPTY_ADDRESS(uuid()))
    const [chainName, setChainName] = usePersistState<string>('chainName', '')
    const [frame, setFrame] = usePersistState<Frame | undefined>('frame', NO_FRAME)
    const [layout, setLayout] = usePersistStateLayout('layout', DEFAULT_LAYOUT)
    const [font, setFont] = usePersistState<Font | undefined>('font', DEFAULT_FONT)
    const [messageColor, setMessageColor] = usePersistState<MessageColor | undefined>('messageColor', DEFAULT_COLOR)
    const [promotion, setPromotion] = usePersistState<number>('promotion', 1.0)
    const [promoCodeId, setPromoCodeId] = usePersistState<string | undefined>('promoCodeId', undefined)

    useEffect(() => {
        handleLoadCachedImages()
    }, [])

    const initialContext = {
        id, setId,
        images, setImages,
        handleCacheImage, handleLoadCachedImages,
        message, setMessage,
        location, setLocation,
        latitude, setLatitude,
        longitude, setLongitude,
        address, setAddress,
        replyBack, setReplyBack,
        chainStartOrContinuation, setChainStartOrContinuation,
        replyAddress, setReplyAddress,
        chainName, setChainName,
        frame, setFrame,
        layout, setLayout,
        font, setFont,
        messageColor, setMessageColor,
        promotion, setPromotion,
        promoCodeId, setPromoCodeId
    }

    return <CurrentPostcardContext.Provider value={initialContext}>
        <InnerPostcardAppPage/>
    </CurrentPostcardContext.Provider>
}

export default PostcardAppPage
