import {
    DEFAULT_STATE,
    getChainSetting,
    getEmptyChain, getMissingInformationForStep,
    getNextButtonDisabled,
    getNextStep,
    getPreviousStep,
    getPrice,
    getReplyBackSetting,
    getState,
    preparePostcardsForSubmission,
    processPostcardsCreation,
    sendToCheckout,
    shouldUpdateUserAddress,
    STEPS
} from "../common/postcardAppUtils";
import {PostcardAppInitialState, PostcardAppState} from "./utils/PostcardAppInitialState";
import {useContext, useEffect, useState} from "react";
import usePostcards from "./utils/usePostcards";
import CurrentPostcardContext from "./utils/currentPostcardContext";
import {useAuthenticator} from "@aws-amplify/ui-react";
import ImageStep from "../common/imagesselection/ImageStep";
import MessageAndLocationStep from "../common/MessageAndLocationStep";
import RecipientStep from "./RecipientStep";
import PersonalAddress from "./PersonalAddress";
import CartStep from "./CartStep";
import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography} from "@mui/material";
import NextDisabledContext from "./utils/nextDisabledContext";
import CurrentStepBreadcrumbs from "./CurrentStepBreadcrumbs/CurrentStepBreadcrumbs";
import {useLocationState} from "../../../utils/useLocationState";
import usePersistState from "../../../utils/usePersistState";
import useUserAttributes from "../../../utils/useUserAttributes";
import {CHAIN_SETTING, REPLY_BACK_SETTING} from "../../../utils/postcard";
import {computePostcardPrice} from "../../../api/api";
import PageWrapper from "../../PageWrapper";
import IsGreetingsContext from "../common/imagesselection/isGreetingsContext";

export const InnerPostcardAppPage = () => {
    const state = getState(useLocationState<PostcardAppState>())

    const initialStep = STEPS.IMAGE

    const [step, setStep] = usePersistState<STEPS>('step', initialStep)
    const [loadingSubmission, setLoadingSubmission] = useState<boolean>(false)
    const [errorMessage, setErrorMessage] = useState<string>('')
    const [isNextDisabled, setIsNextDisabled] = useState<boolean>(true)
    const [dialogOpen, setDialogOpen] = useState<boolean>(false)

    const [authState, setAuthState] = useState<boolean>(false)

    const {
        currentIndex, addPostcard, removePostcard, postcards,
        syncImages, syncMessage, syncLocation, syncLatitude, syncLongitude, syncFrame, syncLayout, syncFont,
        syncMessageColor, syncAddress, syncReplyBackSetting, syncReplyAddressOfFirstPostcard, syncChainSetting, syncChain,
        syncInitialStateOfFirstPostcard
    } = usePostcards()

    const context = useContext(CurrentPostcardContext)

    const {user} = useAuthenticator()
    const {address, setAddress, updateAddress} = useUserAttributes(user?.userId)

    useEffect(() => {
        // Assign initial state to first postcard settings to enable price retrieval
        syncInitialStateOfFirstPostcard(state.initialState)
        if (currentIndex === 0) { // Use only if current index is 0 => don't if cached postcards are more than one
            // Check initial state
            switch (state.initialState) {
                case PostcardAppInitialState.REPLY_FREE:
                    context.setAddress(state.body.addressToPrefill)
                    break
                case PostcardAppInitialState.CHAIN_CONTINUATION_FREE:
                case PostcardAppInitialState.CHAIN_CONTINUATION_TO_BE_PAID:
                    syncChain(state.body.chain)
                    context.setChainStartOrContinuation(true)
                    syncChainSetting(CHAIN_SETTING.CHAIN_PAID)
                    break
            }
            if (state.initialState === PostcardAppInitialState.REPLY_FREE)
                context.setAddress(state.body.addressToPrefill)
        }
        // eslint-disable-next-line
    }, [])

    // Note that the user is FOR SURE authenticated when this function is called
    const onSubmitOrder = async () => {
        setLoadingSubmission(true)
        const postcardSubmissionBundles = postcards.map(postcard => preparePostcardsForSubmission(
            postcard,
            currentIndex === 0 ? state : DEFAULT_STATE
        ))
        const isValid = await processPostcardsCreation(postcardSubmissionBundles)
        if (isValid) {
            const response = await sendToCheckout(postcards.map(postcard => ({
                id: postcard.id, price: computePostcardPrice({
                    id: postcard.id,
                    initialState: postcard.settings.initialState,
                    replyBackSetting: postcard.settings.replyBackSetting,
                    chainSetting: postcard.settings.chainSetting
                })
            })), window, context.promoCodeId)
            if (!response) {
                setErrorMessage('Failed to process submission')
                setLoadingSubmission(false)
                // TODO notify devs
            }
        } else {
            setErrorMessage('Failed to submit postcards')
            setLoadingSubmission(false)
            // TODO notify devs
        }
    }

    useEffect(() => {
        if (address && (
            context.replyAddress.fullName !== address.fullName ||
            context.replyAddress.streetAddress !== address.streetAddress ||
            context.replyAddress.city !== address.city ||
            context.replyAddress.zipCode !== address.zipCode
        )) {
            if (address.fullName !== '' && address.streetAddress !== '' && address.city !== '' && address.zipCode !== '')
                context.setReplyAddress(address)
        }
        // eslint-disable-next-line
    }, [address, currentIndex])

    // eslint-disable-next-line
    useEffect(() => { syncImages(context.images) }, [context.images])
    // eslint-disable-next-line
    useEffect(() => { syncMessage(context.message) }, [context.message])
    // eslint-disable-next-line
    useEffect(() => { syncLocation(context.location) }, [context.location])
    // eslint-disable-next-line
    useEffect(() => { syncLatitude(context.latitude) }, [context.latitude])
    // eslint-disable-next-line
    useEffect(() => { syncLongitude(context.longitude) }, [context.longitude])
    // eslint-disable-next-line
    useEffect(() => { syncAddress(context.address) }, [context.address])
    // eslint-disable-next-line
    useEffect(() => { syncFrame(context.frame) }, [context.frame])
    // eslint-disable-next-line
    useEffect(() => { syncLayout(context.layout) }, [context.layout])
    // eslint-disable-next-line
    useEffect(() => { syncFont(context.font) }, [context.font])
    // eslint-disable-next-line
    useEffect(() => { syncMessageColor(context.messageColor) }, [context.messageColor])
    // eslint-disable-next-line
    useEffect(() => { syncReplyBackSetting(getReplyBackSetting(currentIndex, state.initialState, context.replyBack)) }, [context.replyBack])
    // eslint-disable-next-line
    useEffect(() => { syncReplyAddressOfFirstPostcard(context.replyAddress) }, [context.replyAddress])
    // eslint-disable-next-line
    useEffect(() => { syncChainSetting(getChainSetting(currentIndex, state.initialState, context.chainStartOrContinuation)) }, [context.chainStartOrContinuation])
    useEffect(() => {
        let currentChain = postcards[currentIndex].settings.chain
        if (!currentChain) currentChain = getEmptyChain()
        currentChain.name = context.chainName
        syncChain(currentChain)
        // eslint-disable-next-line
    }, [context.chainName])

    const syncDataToCurrentPostcard = () => {
        if (currentIndex === 0 && postcards.length < 2) return
        const currentPostcard = postcards[currentIndex]
        context.setId(currentPostcard.id)
        context.setImages(currentPostcard.images)
        context.setMessage(currentPostcard.message)
        context.setLocation(currentPostcard.location)
        context.setLatitude(currentPostcard.latitude)
        context.setLongitude(currentPostcard.longitude)
        context.setAddress(currentPostcard.address)
        context.setLayout(currentPostcard.layout)
        context.setFrame(currentPostcard.frame)
        context.setFont(currentPostcard.font)
        context.setMessageColor(currentPostcard.color)
        address && context.setReplyAddress(address)
        context.setReplyBack(currentPostcard.settings.replyBackSetting !== REPLY_BACK_SETTING.NONE)
        context.setChainStartOrContinuation(currentPostcard.settings.chainSetting !== CHAIN_SETTING.NONE)
        if (address) syncReplyAddressOfFirstPostcard(address)
    }

    // eslint-disable-next-line
    useEffect(() => { syncDataToCurrentPostcard() }, [currentIndex])

    const onAddPostcard = () => {
        addPostcard().then(ignored => setStep(STEPS.IMAGE))
    }

    const initialNextDisabledContext = {
        isNextDisabled: isNextDisabled,
        updateIsNextDisabled: () => setIsNextDisabled(getNextButtonDisabled(step, loadingSubmission, authState, context))
    }

    useEffect(() => {
        initialNextDisabledContext.updateIsNextDisabled()
        // eslint-disable-next-line
    }, [step, loadingSubmission, user, authState, context])

    const stepsComponents = [
        <ImageStep/>,
        <MessageAndLocationStep/>,
        <RecipientStep
            price={getPrice(state.initialState)}
            state={state.initialState}
        />,
        <PersonalAddress
            authState={authState}
            setAuthState={setAuthState}
            userAddress={address}
        />,
        <CartStep
            authState={authState}
            setAuthState={setAuthState}
            postcards={postcards}
            onSubmitOrder={onSubmitOrder}
            onAddAnotherPostcard={onAddPostcard}
            onRemovePostcard={removePostcard}
            initialStep={state.initialState}
            setStep={setStep}
        />
    ]

    const next = () => setStep(getNextStep(step, currentIndex, state.initialState, context.replyBack))
    const previous = () => setStep(getPreviousStep(step, currentIndex, state.initialState, context.replyBack))

    return <PageWrapper>
        <IsGreetingsContext.Provider value={{ isGreetings: false }}>
            <Box sx={{ display: 'flex', flexGrow: 0, flexShrink: 0, width: '100%', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', mt: 4, mb: 2 }}>
                <CurrentStepBreadcrumbs
                    currentStep={step}
                    setStep={setStep}
                    nextStep={getNextStep(step, currentIndex, state.initialState, context.replyBack)}
                    isNextEnabled={!isNextDisabled}
                />
            </Box>

            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', p: 2 }}>
                <NextDisabledContext.Provider value={initialNextDisabledContext}>
                    {stepsComponents[step]}
                </NextDisabledContext.Provider>
            </Box>

            <Box sx={{ flexGrow: 0, flexShrink: 0 }}>
                <Typography>{loadingSubmission && 'Loading submission...'}</Typography>
                <Typography>{errorMessage}</Typography>
            </Box>
            <Box sx={{ flexDirection: 'row', display: 'flex', width: '100%', justifyContent: 'center', gap: 4, mb: 8 }}>
                <Box sx={{ display: step === STEPS.IMAGE ? 'none' : 'block' }}>
                    <Button disabled={loadingSubmission} onClick={() => {
                        if (step === STEPS.PERSONAL_ADDRESS && address && shouldUpdateUserAddress(address, context.replyAddress)) {
                            setAddress(context.replyAddress)
                            updateAddress(context.replyAddress).then(() => previous())
                        } else {
                            previous()
                        }
                    }} variant={'contained'} color={'secondary'} className={'no-focus'}>
                        Back
                    </Button>
                </Box>
                <Box sx={{ display: step === STEPS.CART ? 'none' : 'block' }}>
                    <Button onClick={() => {
                        if (isNextDisabled) {
                            // Open error popup
                            setDialogOpen(true)
                        } else {
                            if (step === STEPS.PERSONAL_ADDRESS && address && shouldUpdateUserAddress(address, context.replyAddress)) {
                                setAddress(context.replyAddress)
                                updateAddress(context.replyAddress).then((ignored: any) => next())
                            } else {
                                next()
                            }
                        }
                    }} variant={'contained'} className={'no-focus'} id={STEPS[step]}>
                        Next
                    </Button>
                </Box>
            </Box>
        </IsGreetingsContext.Provider>
        <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)} sx={{ '& .MuiPaper-root': { padding: 2, borderRadius: 4 } }}>
            <DialogTitle>
                Something is missing
            </DialogTitle>
            <DialogContent>
                {getMissingInformationForStep(step, context)}
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setDialogOpen(false)} variant={'contained'}>
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    </PageWrapper>
}
