import React, {Dispatch, SetStateAction, useContext} from "react";
import CurrentPostcardContext from "./utils/currentPostcardContext";
import {
    Box,
    Button,
    Typography,
    Card,
    Divider,
    CardContent, useTheme, useMediaQuery
} from "@mui/material";
import AuthenticatorWrapper from "./AuthenticatorWrapper";
import {Address} from "../../../utils/postcard";
import AddressTextField from "../../AddressTextField";

type PersonalAddressProps = {
    userAddress: Address | null
}

const InnerPersonalAddress = ({ userAddress }: PersonalAddressProps) => {
    const {
        replyAddress, setReplyAddress
    } = useContext(CurrentPostcardContext)

    const theme = useTheme()
    const belowMedium = useMediaQuery(theme.breakpoints.down('md'))

    return <Box sx={{ display: 'flex' }}>
        {!belowMedium && <Box sx={{p: 2, pt: 0, width: 250}}/>}

        <Card sx={{ width: belowMedium ? '100%' : 600, borderRadius: 3, borderWidth: 1, p: 5 }}>
            {!userAddress ? <Box sx={{ display: 'flex', gap: 2, flexDirection: 'column', mb: 1 }}>
                <Typography variant={'h6'}>Write your address for the reply back</Typography>
                <Divider />
            </Box> : <Box sx={{ display: 'flex', gap: 2, flexDirection: 'column', mb: 1 }}>
                <Typography variant={'h6'}>Do you want to modify your address for the reply back?</Typography>
                <Divider />
            </Box>}
            <CardContent sx={{ display: 'flex', flexDirection: 'column', p: 0, mb: 2 }}>
                {/* @ts-ignore */}
                <AddressTextField placeholder={'FULL NAME'} value={replyAddress.fullName} onChange={e => setReplyAddress({ ...replyAddress, fullName: e.target.value })} />
                {/* @ts-ignore */}
                <AddressTextField placeholder={'STREET ADDRESS'} value={replyAddress.streetAddress} onChange={e => setReplyAddress({ ...replyAddress, streetAddress: e.target.value })} />
                {/* @ts-ignore */}
                <AddressTextField placeholder={'CITY'} value={replyAddress.city} onChange={e => setReplyAddress({ ...replyAddress, city: e.target.value })} />
                {/* @ts-ignore */}
                <AddressTextField placeholder={'ZIP CODE'} value={replyAddress.zipCode} onChange={e => setReplyAddress({ ...replyAddress, zipCode: e.target.value })} />
            </CardContent>
            {userAddress && <Button
                variant={'contained'}
                onClick={() => setReplyAddress(userAddress)}
                disabled={userAddress === replyAddress}
            >Reset</Button>}
        </Card>

        {!belowMedium && <Box sx={{p: 2, pt: 0, width: 250}}/>}
    </Box>
}

const PersonalAddress = (props: PersonalAddressProps &
    { authState: boolean, setAuthState: Dispatch<SetStateAction<boolean>> }
) => {
    return <AuthenticatorWrapper authState={props.authState} setAuthState={props.setAuthState}>
        <InnerPersonalAddress {...props} />
    </AuthenticatorWrapper>
}

export default PersonalAddress
