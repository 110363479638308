import PageWrapper from "../components/PageWrapper";
import {useNavigate, useSearchParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {useAuthenticator} from "@aws-amplify/ui-react";
import useUserAttributes from "../utils/useUserAttributes";
import {IDSPriceAndQuantity, processGreetingsOrderSubmission} from "../components/app/common/postcardAppUtils";
import {CacheManager} from "../utils/usePersistState";
import {getBackendEnvironment, invoke} from "../utils/utils";
import {Box, Button, CircularProgress, Typography} from "@mui/material";
import {useLocationState} from "../utils/useLocationState";

const GreetingsBankTransferSuccess = () => {
    const navigate = useNavigate()
    const state = useLocationState<IDSPriceAndQuantity>()
    const [isLoadingSubmission, setIsLoadingSubmission] = useState<boolean>(true)
    const [errorMessage, setErrorMessage] = useState<string>('')
    const { user } = useAuthenticator()
    const { email, name } = useUserAttributes(user?.userId)

    let time = 0

    const processOrder = async (id: string): Promise<boolean> => {
        console.log("Processing order with id: " + id)
        return await processGreetingsOrderSubmission(id)
    }

    useEffect(() => {
        if (time === 0 && email && state) {
            // Bypass duplicate execution bug after stripe redirect
            // eslint-disable-next-line
            time = Date.now()
            const ids: string[] = [state.id]
            ids.length ? processOrder(ids[0])
                .then(result => {
                    if (!result) {
                        setErrorMessage('Error during order submission')
                        // TODO notify error to devs via email (critical customer case)
                        return false
                    } else {
                        CacheManager.clearGreetings()
                            .then(ignored => { })
                            .catch(error => console.warn("Error while clearing cache: ", error))
                        const environment = getBackendEnvironment()
                        return invoke(`stampyemailsender-${environment}`, {
                            subject: 'Order received',
                            message: `
Hey ${name}, 

your Memento Greetings order has been received

To confirm it, please complete the bank transfer:

IBAN: IT88Y3609201600688925943459
BIC: QNTOITM2XXX
Address: MEMENTO S.R.L., Via Merano 3, 20060, Gessate - IT

Amount: ${state.price * state.quantity}

Order details:
- Postcard id: ${ids[0]}

Payment reason to write in the bank transfer: Postcard id

You are just one step from making a super nice surprise to your customers!

Best,

Memento Team 
                            `,
                            recipient: email
                        })
                    }
                })
                .then(_ => setIsLoadingSubmission(false)) : (() => {
                setErrorMessage('Bad request')
                setIsLoadingSubmission(false)
            })()
        }
        // eslint-disable-next-line
    }, [email])

    return <PageWrapper>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: '100%', flexDirection: 'column', px: 2 }}>
            {isLoadingSubmission ? <CircularProgress sx={{ '& svg': { transform: 'scale(3) !important' }, marginBottom: 16 }} /> : errorMessage === '' && <img src={'icons/app/order_confirmation.svg'} alt={'Success!'} style={{marginBottom: 16}}/>}
            {isLoadingSubmission ?
                <Typography variant={'h4'}>Your order is being processed...</Typography> :
                (errorMessage === '' ?
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 1, textAlign: 'center' }}>
                        <Typography variant={'h4'}>Order received!</Typography>
                        {/* @ts-ignore */}
                        <Typography variant={'order-successful'}>Please check your email for order confirmation</Typography>
                    </Box> :
                    <Typography variant={'h4'}>{errorMessage}</Typography>)
            }
            <Box sx={{ mt: 2, gap: 2, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Button variant={'contained'} onClick={() => navigate('/greetings-app')}>
                    Place a new order
                </Button>
            </Box>
        </Box>
    </PageWrapper>
}

export default GreetingsBankTransferSuccess