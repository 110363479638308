import '@aws-amplify/ui-react/styles.css';
import React, {Dispatch, SetStateAction} from "react";
import {Box, Typography, useMediaQuery, useTheme} from "@mui/material";
import {PostcardAppInitialState} from "./utils/PostcardAppInitialState";
import {getPriceFromSettingAndInitialState, STEPS} from "../common/postcardAppUtils";
import AuthenticatorWrapper from "./AuthenticatorWrapper";
import {Postcard} from "../../../utils/postcard";
import CartPostcardRecap from "../../postcardrecap/cartpostcardrecap/CartPostcardRecap";
import Checkout from "../common/Checkout";

type CartStepProps = {
    onSubmitOrder: () => Promise<void>,
    onAddAnotherPostcard: () => void,
    onRemovePostcard: (id: string) => void,
    postcards: Postcard[]
    initialStep: PostcardAppInitialState
    setStep: (newState: STEPS) => STEPS
}

const InnerCartStep = ({ onSubmitOrder, onAddAnotherPostcard, onRemovePostcard, postcards, initialStep, setStep }: CartStepProps) => {
    let totalPrice = 0
    const prices = postcards.map((postcard, i) => {
        const price = getPriceFromSettingAndInitialState(postcard.settings, i === 0 ? initialStep : PostcardAppInitialState.DEFAULT)
        totalPrice += price
        return price
    })

    const theme = useTheme()
    const belowMedium = useMediaQuery(theme.breakpoints.down('md'))

    return <Box sx={{ display: 'flex', width: '100%', flexDirection: 'column' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'start', gap: 0.5, mb: 2 }}>
            <Typography variant={'h6'}>Shopping cart</Typography>
            <Typography variant={'body1'}>You have {postcards.length} item{postcards.length === 1 ? '' : 's'} in your cart</Typography>
        </Box>

        <Box sx={{ display: 'flex', flexDirection: belowMedium ? 'column' : 'row', alignItems: 'start', width: '100%' }}>
            <Box sx={{ width: belowMedium ? '100%' : 800, mr: belowMedium ? 0 : 4, flexShrink: 0 }}>
                {postcards.map((postcard, i) => <CartPostcardRecap
                    postcard={postcard}
                    key={postcard.id}
                    onRemovePostcard={onRemovePostcard}
                    onlyOnePostcard={postcards.length === 1}
                    price={prices[i]}
                    setStep={setStep}
                />)}
            </Box>

            <Checkout
                totalPrice={totalPrice}
                onSubmitOrder={onSubmitOrder}
                isAddAnotherPostcard={true}
                isPromotionEnabled={true}
                onAddAnotherPostcard={onAddAnotherPostcard}
            />
        </Box>
    </Box>
}

const CartStep = (props: CartStepProps &
    { authState: boolean, setAuthState: Dispatch<SetStateAction<boolean>> }
) => {
    return <AuthenticatorWrapper authState={props.authState} setAuthState={props.setAuthState}>
        <InnerCartStep {...props} />
    </AuthenticatorWrapper>
}

export default CartStep