import {Dispatch, SetStateAction, useCallback} from "react";
import {Postcard, PostcardSettings} from "../../../../utils/postcard";

export type SyncerStateDataType = {
    _currentIndex: number,
    _postcards: Postcard[],
    _setPostcards: ((newState: Postcard[]) => Postcard[]) | Dispatch<SetStateAction<Postcard[]>> | ((newState: Postcard[]) => Promise<Postcard[]>)
}

const useFieldSyncer = <T extends keyof Postcard>(
    fieldName: T,
    syncerStateData: SyncerStateDataType
): (value: Postcard[T]) => void => {
    const {
        _postcards,
        _currentIndex,
        _setPostcards,
    } = syncerStateData
    return useCallback((value: Postcard[T]) => {
        const __postcards = _postcards
        __postcards[_currentIndex][fieldName] = value
        _setPostcards(__postcards)
    }, [_currentIndex, _postcards, _setPostcards, fieldName])
}

const useSettingsFieldSyncer = <T extends keyof PostcardSettings>(
    fieldName: T,
    syncerStateData: SyncerStateDataType
): (value: PostcardSettings[T]) => void => {
    const {
        _postcards,
        _currentIndex,
        _setPostcards
    } = syncerStateData
    return useCallback( (value: PostcardSettings[T]) => {
        const __postcards = _postcards
        __postcards[_currentIndex].settings[fieldName] = value
        _setPostcards(__postcards)
    }, [_currentIndex, _postcards, _setPostcards, fieldName])
}

export { useFieldSyncer, useSettingsFieldSyncer }
