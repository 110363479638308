import {useContext, useEffect, useState} from "react";
import ImagesSelection from "./ImagesSelection";
import CurrentPostcardContext from "../../postcard-app/utils/currentPostcardContext";
import {Typography, Box, useTheme, useMediaQuery} from "@mui/material";
import {getImageFromLayout, LAYOUT} from "../../../../utils/layout";
import usePersistState from "../../../../utils/usePersistState";
import {getLayouts} from "../../../../api/api";
import GreetingsPostcardContext from "../../greetings-postcard-app/utils/greetingsPostcardContext";
import IsGreetingsContext from "./isGreetingsContext";
import {CommonContextType} from "../utils/commonContextType";

const ImageStep = () => {
    const { isGreetings } = useContext(IsGreetingsContext)

    const context = (isGreetings ? GreetingsPostcardContext : CurrentPostcardContext) as unknown as React.Context<CommonContextType>

    const {
        layout, setLayout
    } = useContext<CommonContextType>(context)

    const [layouts, setLayouts] = useState<LAYOUT[]>([])
    const [isLoadingLayouts, setIsLoadingLayouts] = useState<boolean>(false)
    const [layoutErrorMessage, setLayoutErrorMessage] = useState<string>('')
    const [selectedLayout, setSelectedLayout] = usePersistState<LAYOUT>(isGreetings ? 'greetings_selectedLayout' : 'selectedLayout', layout)

    const theme = useTheme()
    const belowMedium = useMediaQuery(theme.breakpoints.down('md'))

    const fetchLayouts = async () => {
        const _layouts = getLayouts()
        if (_layouts)
            setLayouts(_layouts)
        else setLayoutErrorMessage('Failed to load layouts')
    }

    useEffect(() => {
        setIsLoadingLayouts(true)
        fetchLayouts()
            .then(() => setIsLoadingLayouts(false))
    }, [])

    useEffect(() => {
        setLayout(selectedLayout)
    }, [selectedLayout, setLayout])

    // @ts-ignore
    return <Box sx={{ display: 'flex', flexDirection: belowMedium ? 'column' : 'row-reverse', alignItems: belowMedium ? 'center' : 'auto' }} >
        {!belowMedium && <Box sx={{p: 2, pt: 0, width: 250}}/>}

        <ImagesSelection layout={layout} />

        <Box sx={{ p: belowMedium ? 0 : 2, pt: belowMedium ? 3 : 0, pb: 0, width: belowMedium ? '100%' : 250 }}>
            <Typography variant={'h6'}>Choose the layout</Typography>
            <Box sx={{ p: 1, pt: 2, height: '100%' }}>
                {isLoadingLayouts ?
                    <Typography>Loading layouts...</Typography> :
                    <Box>
                        <Typography>{layoutErrorMessage}</Typography>
                        {layoutErrorMessage === '' && <>
                            <Box sx={{ display: 'flex', flexDirection: belowMedium ? 'row' : 'column', justifyContent: 'space-between', height: '100%', width: belowMedium ? '100%' : 'auto' }}>
                                {layouts.length === 0 ?
                                    'There are no layouts available' :
                                    <>
                                        {layouts.map(layout =>
                                            <Box key={layout} style={{
                                                    padding: 5,
                                                    width: belowMedium ? 'auto' : 190,
                                                    height: belowMedium ? 'auto' : 130,
                                                    cursor: 'pointer',
                                                    border: layout === selectedLayout ? '1px solid ' + theme.palette.primary.main : 'none'
                                                }}
                                                onClick={() => {setSelectedLayout(layout)}}>
                                                <img src={getImageFromLayout(layout)} alt={'Layout'} style={{ width: belowMedium ? '100%' : 180, height: belowMedium ? '100%' : 120 }} />
                                            </Box>)}
                                    </>
                                }
                            </Box>
                        </>}
                    </Box>}
            </Box>
        </Box>
    </Box>
}

export default ImageStep