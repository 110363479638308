import {Address, Font, Image, MessageColor} from "../../../utils/postcard";
import {LAYOUT} from "../../../utils/layout";
import CartPostcardRecapTemplate from "./CartPostcardRecapTemplate";

type GreetingsCartPostcardRecapProps = {
    id: string
    message: string
    font?: Font
    color?: MessageColor
    location: string
    latitude: number
    longitude: number
    layout: LAYOUT
    images: Image[]
    address: Address

    price: number
}

const GreetingsCartPostcardRecap = (props: GreetingsCartPostcardRecapProps) => {
    return <CartPostcardRecapTemplate
        { ...props }
        isDelete={false}
    />
}

export default GreetingsCartPostcardRecap