import { signOut } from "aws-amplify/auth";
import {useLocation, useNavigate, useSearchParams} from "react-router-dom";
import {ReactNode, useState} from "react";
import {useAuthenticator} from "@aws-amplify/ui-react";
import {
    AppBar,
    Box,
    Button,
    Divider,
    Drawer,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    Toolbar,
    Link, useTheme, Typography, Card, TextField, CircularProgress, useMediaQuery
} from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import DoneIcon from '@mui/icons-material/Done';
import {getBackendEnvironment, invoke} from "../utils/utils";
import {CacheManager} from "../utils/usePersistState";

type PageWrapperProps = {
    children: ReactNode
}

enum EmailState {
    INITIAL, SENDING, SENT
}

const PageWrapper = ({ children }: PageWrapperProps) => {

    const navigate = useNavigate()
    const [searchParams] = useSearchParams()
    const location = useLocation()
    const { user } = useAuthenticator()
    const [mobileOpen, setMobileOpen] = useState<boolean>(false);
    const [email, setEmail] = useState<string>('')
    const [isLoadingEmail, setIsLoadingEmail] = useState<EmailState>(EmailState.INITIAL)
    const theme = useTheme()

    const handleDrawerToggle = () => setMobileOpen(!mobileOpen)

    const belowMd = useMediaQuery(theme.breakpoints.down('sm'));

    const navItems: { name: string, path: string }[] = [
        { name: 'Home', path: '/' },
        { name: 'Send your postcard', path: '/app' },
        { name: 'Profile', path: '/profile' },
        { name: 'Ambassadors', path: '/ambassador'},
        { name: 'Business', path: '/greetings'},
        { name: 'FAQ', path: '/faq' }
    ]

    const logOut = async () => {
        try {
            await signOut()
            // Clear cache
            await CacheManager.clear()
                .then(ignored => { })
                .catch(error => console.warn("Error while clearing cache: ", error))
            await CacheManager.clearGreetings()
                .then(ignored => { })
                .catch(error => console.warn("Error while clearing greetings cache: ", error))
        } catch (e) {
            console.log("Error while logging user out: ", e)
        }
    }

    const getParamsString = (params: URLSearchParams): string => {
        const paramsIterator = params.entries()
        const fields = []
        for (const field of paramsIterator) {
            fields.push(field[0] + '=' + field[1])
        }
        return fields.join('&')
    }

    const goToSignIn = () => navigate('/auth', {
        state: {
            redirectsTo: location.pathname,
            paramsToRemember: getParamsString(searchParams)
        }
    })

    const handleSubmit = async () => {
        setIsLoadingEmail(EmailState.SENDING)
        try {
            const environment = getBackendEnvironment()
            await invoke(`stampyemailsender-${environment}`, {
                message: 'New email address subscribed: ' + email,
                subject: '[AUTO] New email address subscribed',
                recipient: 'admin@memento-card.it'
            })
        } catch (e) {
            console.warn('Error while trying to send internal email')
        }
        setIsLoadingEmail(EmailState.SENT)
        setEmail('')
    }

    const drawer = (
        <Box onClick={handleDrawerToggle}>
            <Box sx={{ py: 0.6 }}>
                <Toolbar sx={{ width: '100%', justifyContent: 'space-between' }}>
                    <Box component="div">
                        <Box
                            component="img"
                            sx={{ height: 32, cursor: 'pointer' }}
                            alt="Memento"
                            src={"images/logo_white.png"}
                        />
                    </Box>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        sx={{ display: { md: 'none' } }}
                    >
                        <MenuIcon sx={{ color: 'white', '& path': { fill: 'white' } }} />
                    </IconButton>
                </Toolbar>
            </Box>
            <Divider sx={{ backgroundColor: 'white' }} />
            <List>
                {navItems.map((item) => (
                    <ListItem key={item.name} disablePadding>
                        <ListItemButton sx={{ textAlign: 'left' }} onClick={() => navigate(item.path)}>
                            <ListItemText primary={item.name} sx={{ '& span': { color: 'white', fontSize: 20, fontWeight: 'bold', pl: 2 } }} />
                        </ListItemButton>
                    </ListItem>
                ))}
                <ListItem key={'auth-button'} disablePadding>
                    <ListItemButton sx={{ textAlign: 'left' }} onClick={user ? logOut : goToSignIn}>
                        <ListItemText primary={user ? 'Log out' : 'Sign in'} sx={{ '& span': { color: 'white', fontSize: 20, fontWeight: 'bold', pl: 2 } }} />
                    </ListItemButton>
                </ListItem>
            </List>
        </Box>
    );

    const container = window !== undefined ? () => window.document.body : undefined;
    const footerHeightMd = 300
    const footerHeightLg = 240
    const footerHeight = 200
    const smallFooterHeight = 120
    const contentMaxWidth = 1400
    const currentYear = (new Date()).getFullYear()

    const pagesWithDarkBg = [
        '/app',
        '/ambassador-contact',
        '/payment-success',
        '/postcard-scan',
        '/profile',
        '/faq',
        '/contact',
        '/greetings-app'
    ]

    const pagesWithFormFooter = [
        '/',
        '/ambassador',
        '/greetings',
        '/coming-soon'
    ]

    return <Box  sx={{ display: 'flex', backgroundColor: pagesWithDarkBg.includes(location.pathname) ? theme.palette.secondary.main : 'transparent' }}>
        <AppBar component={'nav'} sx={{ justifyContent: 'center', py: 0.6 }}>
            <Toolbar sx={{ justifyContent: 'space-between', flexDirection: { xs: 'row-reverse', md: 'row' } }}>
                <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    edge="start"
                    onClick={handleDrawerToggle}
                    sx={{ mr: 2, display: { md: 'none' } }}
                >
                    <MenuIcon sx={{ color: 'white', '& path': { fill: 'white' } }}  />
                </IconButton>
                <Box component="div">
                    <Box
                        component="img"
                        sx={{ height: 32, cursor: 'pointer' }}
                        alt="Memento"
                        src={"images/logo_white.png"}
                        onClick={() => navigate('/')}
                    />
                </Box>
                <Box sx={{ display: { xs: 'none', md: 'flex' }, alignSelf: 'center', flexDirection: 'row' }}>
                    {navItems.map(item => <Box sx={{ pl: 2, pr: 2 }} key={item.name}>
                        <Link
                            href={item.path}
                            color={theme.palette.primary.contrastText}
                        >{item.name}</Link>
                    </Box>)}
                </Box>
                <Box sx={{ display: { xs: 'none', md: 'block' } }}>
                    {user ? <Button
                        variant={"contained"}
                        color={'secondary'}
                        onClick={logOut}
                    ><span>Log out</span></Button> : <Button
                        variant={"contained"}
                        color={'secondary'}
                        onClick={goToSignIn}
                    ><span>Sign up</span></Button>}
                </Box>
            </Toolbar>
        </AppBar>
        <nav>
            <Drawer
                container={container}
                variant="temporary"
                open={mobileOpen}
                onClose={handleDrawerToggle}
                ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                }}
                sx={{
                    display: { xs: 'block', md: 'none' },
                    '& .MuiDrawer-paper': { boxSizing: 'border-box', width: '100vw', backgroundColor: theme.palette.primary.main },
                }}
            >
                {drawer}
            </Drawer>
        </nav>
        <Box sx={{ width: '100%', display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
            <Toolbar sx={{ width: '100%' }} />
            <Box
                component="main"
                sx={{
                    width: '100vw',
                    maxWidth: contentMaxWidth,
                    // @ts-ignore
                    minHeight: `calc(100vh - ${theme.components?.MuiAppBar?.defaultProps?.sx.height ?? 0}px - ${smallFooterHeight}px)`
                }}
            >
                {children}
            </Box>
            {pagesWithFormFooter.includes(location?.pathname) && <Box sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    marginBottom: -1,
                }}>
                    <Card sx={{
                        p: 3,
                        mb: -5,
                        width: 'calc(100% - 100px)',
                        maxWidth: `calc(${contentMaxWidth}px - 100px)`,
                        backgroundColor: 'white',
                        zIndex: 10,
                        borderRadius: 5,
                        borderWidth: 1,
                        borderColor: 'lightgray',
                        borderStyle: 'solid',
                        display: 'flex',
                        flexDirection: {xs: 'column', md: 'row'}
                    }}>
                        <Box sx={{
                            width: {xs: '100%', md: '50%'},
                            pt: {xs: 0, md: 2},
                            pr: {sx: 0, md: 2},
                            pb: {xs: 2, md: 0}
                        }}>
                            <Typography variant={'h4'} style={{ marginRight: 32, fontSize: belowMd ? 28 : 40 }}>
                                <span style={{color: theme.palette.primary.main}}>
                                    Subscribe and get exclusive deals & offers
                                </span>
                            </Typography>
                        </Box>
                        <Box sx={{width: {xs: '100%', md: '50%'}, pl: {xs: 0, md: 2}, alignSelf: 'center'}}>
                            <Box sx={{display: 'flex', flexDirection: 'row'}}>
                                <Box sx={{ width: '100%', mr: { xs: 0, md: 3 }, display: 'flex', alignItems: 'center' }}>
                                    <TextField
                                        label={'Enter your email'}
                                        style={{ flexGrow: 1, marginRight: 24 }}
                                        type={'email'}
                                        value={email}
                                        onChange={e => setEmail(e.target.value)}
                                    />
                                    <Button variant={'contained'} color={'primary'} style={{ height: '100%', width: 98 }} onClick={handleSubmit} disabled={isLoadingEmail === EmailState.SENDING}>
                                        {isLoadingEmail === EmailState.SENT ? <DoneIcon sx={{ color: 'white', '& path': { fill: 'white' } }} /> :
                                            (isLoadingEmail === EmailState.SENDING ? <CircularProgress sx={{ width: '28px !important' }} />
                                                : 'Submit')
                                        }
                                    </Button>
                                </Box>
                            </Box>
                            <Box>
                                <Typography sx={{ fontSize: 10, pt: 0.5 }}>
                                    By hitting 'Submit' you express your consent to receiving commercial communications from Memento
                                </Typography>
                            </Box>
                        </Box>
                    </Card>
                    <Box sx={{width: '100vw', height: 64, backgroundColor: theme.palette.primary.main, zIndex: 2}}/>
            </Box>}
            <Box
                component="footer"
                sx={{
                    width: '100%',
                    height: location?.pathname === '/' ? { xs: 'auto', sm: footerHeightMd, md: footerHeightLg, lg: footerHeight } : { xs: 'auto', sm: smallFooterHeight },
                    backgroundColor: theme.palette.primary.main,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    zIndex: 2
                }}
            >
                <Box sx={{
                    width: '100%',
                    maxWidth: contentMaxWidth,
                    p: 3,
                    color: theme.palette.primary.contrastText
                }}>
                    <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, width: '100%', alignItems: 'stretch' }}>
                        <Box sx={{ width: { xs: 'calc(100%)', sm: 'calc(33.33%)' }, display: 'flex', flexDirection: 'column', alignItems: { xs: 'center', sm: 'start'}, pb: { xs: 3, sm: 0 } }}>
                            {location?.pathname === '/' && <span>
                                Memento S.r.l.<br/>
                                Milan, Italy<br/>
                                C.F., P.IVA and R.I. MI: 13344660967 - Cap. Soc. € 10.000,00<br/><br/>
                            </span>}

                            Copyright © {currentYear} Memento S.r.l. All rights reserved
                        </Box>
                        <Box sx={{ width: { xs: 'calc(100%)', sm: 'calc(33.33%)' }, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', pb: { xs: 2, sm: 0 } }}>
                            <Link href={'https://www.facebook.com/profile.php?id=61556541204721'} style={{ marginRight: 24 }}>
                                <img src={'icons/footer/facebook.svg'} style={{ height: 32, width: 32 }} alt={'Instagram'} draggable={false} />
                            </Link>
                            <Link href={'https://www.instagram.com/memento_card'} style={{ marginRight: 24 }}>
                                <img src={'icons/footer/instagram.svg'} style={{ height: 32, width: 32 }} alt={'Facebook'} draggable={false} />
                            </Link>
                            <Link href={'https://www.linkedin.com/company/memento-card/about/'}>
                                <img src={'icons/footer/linkedin.svg'} style={{ height: 32, width: 32 }} alt={'LinkedIn'} draggable={false} />
                            </Link>
                        </Box>
                        <Box sx={{ width: { xs: 'calc(100%)', sm: 'calc(33.33%)' }, display: 'flex', flexDirection: 'column', alignItems: { xs: 'center', sm: 'end'}, justifyContent: 'center', pb: { xs: 1, sm: 0 } }}>
                            <Link href={'/contact'} sx={{ width: '100%', textAlign: { xs: 'center', sm: 'end' } }}>Contact us</Link>
                            {location.pathname === '/' && <Link href={'/privacy-policy'}
                                   sx={{width: '100%', textAlign: {xs: 'center', sm: 'end'}, mt: 1}}>Privacy
                                policy</Link>}
                            {location.pathname === '/' && <Link href={'/terms-and-conditions'}
                                   sx={{width: '100%', textAlign: {xs: 'center', sm: 'end'}}}>Terms and
                                conditions</Link>}
                            {location.pathname === '/' && <Link href={'/cookie-policy'}
                                    sx={{width: '100%', textAlign: {xs: 'center', sm: 'end'}}}>Cookie policy</Link>}
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    </Box>
}

export default PageWrapper