import {useContext, useState} from "react";
import {PostcardWidthContext} from "../../templates/PostcardTemplate";
import EditMessageBox from "./EditMessageBox";
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Typography,
    useTheme
} from "@mui/material";
import {EditMessageProps} from "./message";

const EditMessageComponentSmall = ({
                                       message, font, color, setMessage
                                   }: EditMessageProps) => {
    const [editMessage, setEditMessage] = useState<boolean>(false)
    const onClickEditMessageBox = () => setEditMessage(true)

    const theme = useTheme();

    const postcardWidth = useContext(PostcardWidthContext).width
    const buttonDefaults = theme.components?.MuiButton?.defaultProps?.sx ? theme.components.MuiButton.defaultProps.sx : {}

    return <>
        <Box sx={{ pl: '5.64%', width: '47.88%' }}>
            {!message ? <EditMessageBox
                onClick={onClickEditMessageBox}
                isSmall={true}
                postcardWidth={postcardWidth}
            /> : <Typography sx={{
                fontSize: postcardWidth * 0.0292,
                fontFamily: font ?? 'auto',
                color: color ?? 'auto',
                pt: postcardWidth * 0.0282 / 8,
                whiteSpace: 'pre-wrap',
            }} onClick={onClickEditMessageBox}
            >
                {message}
            </Typography>}
        </Box>
        <Dialog
            open={editMessage}
            fullScreen
        >
            <DialogTitle>
                <Typography variant={'h6'}>Write your message</Typography>
            </DialogTitle>
            <DialogContent>
                <Box sx={{ width: '100%', height: 200, pt: 1 }}>
                    <TextField multiline sx={{
                        width: 'calc(100% - 40px)',
                        height: '100%',
                        '& .MuiInputBase-root': {
                            height: '100% !important',
                            alignItems: 'start',
                            borderRadius: 2
                        },
                        '& textarea': {
                            fontFamily: font ?? 'auto',
                            color: color ?? 'auto',
                        },
                        // @ts-ignore
                    }} value={message} onChange={e => setMessage(e.target.value)} label={'Message'} />
                </Box>
            </DialogContent>
            <DialogActions sx={{ p: 3 }}>
                <Button variant={"contained"} onClick={() => setEditMessage(false)} sx={{ width: '50%', ...buttonDefaults }}> Save </Button>
            </DialogActions>
        </Dialog>
    </>
}

export default EditMessageComponentSmall