import {GreetingsPostcard, Image, Postcard} from "../utils/postcard";
import {Box, Button, Card, CardContent, IconButton, Typography, useMediaQuery, useTheme} from "@mui/material";
import {useState} from "react";
import PostcardBackLarge from "./postcardrecap/PostcardBackLarge";
import getSmallImageComponent from "./postcardrecap/SmallImageComponent";
import {EMPTY_ADDRESS} from "./app/common/postcardAppUtils";

type PostcardRecapProps = {
    postcard: Postcard
    handleAddToYourPostcards?: () => Promise<void>
    isPostcardAlreadyClaimed?: boolean
    isPostcardClaimedByCurrentUser?: boolean
    isMyPostcardPage?: boolean,
    isGreetings?: false
} | {
    postcard: GreetingsPostcard
    isGreetings: true
    handleAddToYourPostcards?: () => Promise<void>
    isPostcardAlreadyClaimed?: boolean
    isPostcardClaimedByCurrentUser?: boolean
    isMyPostcardPage?: boolean
}

const validateImages = (images: Image[]): boolean => {
    if (images.length <= 0) return false

    for (let image of images) {
        if (image.index === undefined) return false
        if (!image.path) return false
    }
    return true
}

const PostcardRecap = ({ postcard, handleAddToYourPostcards, isPostcardAlreadyClaimed, isPostcardClaimedByCurrentUser, isMyPostcardPage, isGreetings }: PostcardRecapProps) => {
    const [showBack, setShowBack] = useState<boolean>(false)
    const theme = useTheme()
    const belowMedium = useMediaQuery(theme.breakpoints.down('md'))

    const font = postcard.font
    const messageColor = postcard.color

    return <Card sx={{ width: belowMedium || isMyPostcardPage ? '100%' : 800, mb: 2 }} className={'cart-postcard-recap'}>
        <CardContent sx={{display: 'flex', flexDirection: 'column', p: belowMedium ? 1.5 : 3, pb: '16px !important'}}>
            <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row', mb: 2 }}>
                <Box sx={{ display: 'flex', flexDirection: 'row', p: belowMedium ? 1.5 : 0, justifyContent: 'space-between', width: '100%', gap: 2 }}>
                    {!isGreetings && <Box sx={{display: 'flex', flexDirection: 'column', flexShrink: 0}}>
                        {/* TODO check for long strings and trim */}
                        <Typography variant={'h6'}
                                    // @ts-ignore
                                    sx={{textTransform: 'capitalize'}}>{postcard.address.fullName}</Typography>
                        <Typography variant={'body1'}
                                    // @ts-ignore
                                    sx={{textTransform: 'capitalize'}}>{postcard.address.city}</Typography>
                    </Box>}
                    <Box>
                        {!isMyPostcardPage && (isPostcardClaimedByCurrentUser ? <Typography sx={{ textAlign: 'end' }}>This postcard has been already claimed by another user</Typography> : (isPostcardAlreadyClaimed ? <img alt={'You saved this postcard'} src={'icons/saved.svg'} width={32} height={32}/> :
                            <IconButton onClick={handleAddToYourPostcards}>
                                <img alt={'Save postcard'} src={'icons/save.svg'} width={32} height={32}/>
                            </IconButton>))
                        }
                    </Box>
                </Box>
            </Box>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 1,
                alignItems: 'center',
                mr: 0,
                flexShrink: 0,
                width: '100%'
            }}>
                {showBack ? <Box sx={{width: '100%', height: 'auto'}}>
                    <PostcardBackLarge
                        messageTextField={false}
                        message={postcard.message}
                        font={font ? font.name : undefined}
                        color={messageColor ? messageColor.hex : undefined}
                        locationTextField={false}
                        location={postcard.location}
                        recipientSpace={false}
                        recipientTextField={false}
                        // @ts-ignore
                        address={isGreetings ? EMPTY_ADDRESS('') : postcard.address}
                        latitude={postcard.latitude}
                        longitude={postcard.longitude}
                    />
                </Box> : (validateImages(postcard.images) ? getSmallImageComponent(postcard.layout, postcard.images) : <Typography>
                    There was an error loading this image
                </Typography>)}
                <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', mt: 1, justifyContent: 'center' }}>
                    <Box sx={{ flexShrink: 0 }}>
                        <Button onClick={() => setShowBack(!showBack)} variant={'contained'} color={'secondary'}>
                            <span>
                                <img width={28} height={28} alt={'Rotate'} src={'icons/app/cartstep/rotate.svg'} draggable={false} style={{ marginRight: 8 }}/>
                                    Turn
                            </span>
                        </Button>
                    </Box>
                </Box>
            </Box>
        </CardContent>
    </Card>
}

export default PostcardRecap